import dayjs from 'dayjs';
import { Fragment, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import config from "../config.js";
import { genericErrorMsg } from "../utils.js";
import Select from 'react-select';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';

export default function AddRateGroupModal(props) {
    
    const [errorMsg, setErrorMsg] = useState(null);
    
    const [roomTypesEnabled, setRoomTypesEnabled] = useState(props.roomTypes.map(rt=>false));

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.add-rate-group-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {
            fetch(`${config.server_base_url}/api/rateGroups` + (props.rateGroup && !props.isCloning ? `/${props.rateGroup.name}`: ''), 
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                name: document.getElementsByName('name')[0].value.replace(/ /g, ''),
                description: document.getElementsByName('description')[0].value,
                isMonthlyRates: document.querySelector('#isMonthlyRates').checked,
                roomTypeRates: props.roomTypes.filter((roomType, index) => roomTypesEnabled[index]).map((roomType)=> {
                    return {
                        roomTypeId: roomType.id,
                        roomPrice: parseFloat(document.getElementsByName(`roomPrice${roomType.id}`)[0].value),
                        mealsPrice: parseFloat(document.getElementsByName(`mealsPrice${roomType.id}`)[0].value),
                        isBookable: document.getElementsByName(`bookable${roomType.id}`)[0].checked
                    }
                })
              })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    const updateTotalPrice = (rtId) => {
        const total = parseFloat(document.getElementsByName(`roomPrice${rtId}`)[0].value) + parseFloat(document.getElementsByName(`mealsPrice${rtId}`)[0].value);
        document.querySelector(`.totalPrice${rtId}`).innerHTML = `€${isNaN(total) ? 0.00 : total}`;
    }

    useEffect(()=> {
        document.getElementsByName('name')[0].focus();
        if (props.rateGroup!=null) {
            setRoomTypesEnabled(props.rateGroup.roomTypes.map(rt=>rt.rate!=null));
        }
    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='add-rate-group-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{props.rateGroup!=null && !props.isCloning ? 'Update' : 'Add'} Rate Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='add-rate-group-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        {props.rateGroup!=null && !props.isCloning ? <p className='form-field-info'>A rate group's name and existing rates cannot be changed after it has been created. You can only add rates for new room types.</p> : <span></span>}
                        <div>
                            <label>Name:</label>
                            <div className='form-field-info'>This is a name that guests might see. No spaces please.</div>
                            <Form.Control disabled={props.rateGroup!=null && !props.isCloning} type="text" required name='name' defaultValue={props.rateGroup?.name} />
                        </div>
                        <div>
                            <label>Description:</label>
                            <div className='form-field-info'>This field will not be shown to guests.</div>
                            <Form.Control type="text" required name='description' defaultValue={props.rateGroup?.description} />
                        </div>
                        <div className='room-type-rate-row room-type-rate-header'>
                            <label>Room Type</label>
                            <label>Room Price <br/>(incl. tax)</label>
                            <label>Meals Price <br/>(incl. tax)</label>
                            <label>Total</label>
                            <label>Bookable <br/>by Guests</label>
                        </div>
                        {props.roomTypes.map((roomType, index)=> {
                            const editRoomTypeRate = props.rateGroup?.roomTypes.find(rt=>rt.id==roomType.id);
                            const editRate = editRoomTypeRate?.rate;

                            return <div key={roomType.id} className='room-type-rate-row'>
                                <Form.Check disabled={props.rateGroup!=null && !props.isCloning && editRate!=null} type='checkbox' defaultChecked={editRate!=null} name={`enabled${roomType.id}`} id={`enabled${roomType.id}`} label={roomType.name} onChange={(e)=>{ const clone = [...roomTypesEnabled]; clone[index] = e.target.checked; setRoomTypesEnabled(clone)}}/>
                                {roomTypesEnabled[index] ? 
                                    <Fragment>
                                        <Form.Control step="any" disabled={props.rateGroup!=null && !props.isCloning && editRate!=null} type="" name={`roomPrice${roomType.id}`} required defaultValue={editRate!=null ? editRate.roomPrice : 0} onChange={(e)=>updateTotalPrice(roomType.id)} />
                                        <Form.Control step="any" disabled={props.rateGroup!=null && !props.isCloning && editRate!=null} type="number" name={`mealsPrice${roomType.id}`} required defaultValue={editRate!=null ? editRate.mealsPrice: 0} onChange={(e)=>updateTotalPrice(roomType.id)} />
                                        <label className={`totalPrice${roomType.id}`}>€{editRate!=null ? (editRate.roomPrice + editRate.mealsPrice).toFixed(2) : 0.00}</label>
                                        <Form.Check defaultChecked={props.rateGroup==null || (editRoomTypeRate!=null && editRoomTypeRate.isBookable)} type='checkbox' name={`bookable${roomType.id}`} id={`bookable${roomType.id}`} />
                                    </Fragment> : <Fragment></Fragment>}
                            </div>
                        })}
                        <div>
                            <label htmlFor='isMonthlyRates'>Monthly:</label>
                            <div className='form-field-info'>Whether the rates below are for one month. Check this only for Village Houses rate groups.</div>
                            <Form.Check type='checkbox' name='isMonthlyRates' id='isMonthlyRates' defaultChecked={props.rateGroup?.isMonthlyRates ?? false} />
                        </div>                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}