import dayjs from 'dayjs';
import { Fragment, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import config from "../config.js";

export default function ChangeRoomBookingDatesModal(props) {
    
    const [startDate, setStartDate] = useState(dayjs(props.roomBooking.startDate).toDate());
    const [endDate, setEndDate] = useState(dayjs(props.roomBooking.endDate).toDate());
    const [errorMsg, setErrorMsg] = useState(null);

    const save = () => {
        fetch(`${config.server_base_url}/api/roombookings/${props.roomBooking.id}/changeDates`, 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            startDate: dayjs(startDate).format('YYYY-MM-DD'), 
            endDate:dayjs(endDate).format('YYYY-MM-DD')
          })
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) });
    }

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Booking Dates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='change-room-booking-dates-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div className='form-row dates-row'>
                            <div>
                                <label>From:</label>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} onKeyDown={(e) => { e.preventDefault();}} />
                            </div>
                            <div>
                                <label>To:</label>
                                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} minDate={dayjs(startDate).add(1, 'day').toDate()} onKeyDown={(e) => { e.preventDefault();}} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}