import { Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import config from "../config.js";
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import Select from 'react-select';

export default function ChangeBookingModal(props) {
    
    const [errorMsg, setErrorMsg] = useState(null);

    const [selectedPaymentSchedule, setSelectedPaymentSchedule] = useState(props.booking.paymentSchedule);
    const [selectedRegistrationFormType, setSelectedRegistrationFormType] = useState(props.booking.registrationFormType);

    const save = (e) => {
        e.preventDefault();

        const editForm = document.querySelector('.edit-booking-form');
        editForm.classList.add('was-validated');
    
        if (editForm.checkValidity()===true) {
          fetch(`${config.server_base_url}/api/bookings/${props.booking.id}/updateBooking`, 
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ 
                paymentSchedule: selectedPaymentSchedule,
                guestCanChooseRoomType: document.querySelector('#guestCanChooseRoomType').checked,
                registrationFormType: props.booking.type=='Guest' ? selectedRegistrationFormType : 'default'
              })          
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
    }

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Booking Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate className="edit-booking-form" onSubmit={save}>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div className='form-row'>
                            <label>Payment schedule:</label>
                            <Select options={props.paymentSchedules} defaultValue={()=>props.paymentSchedules.find(ps=>ps.value==props.booking.paymentSchedule)} onChange={(ps)=>setSelectedPaymentSchedule(ps.value)}/>
                        </div>
                        <div className='form-row'>
                            <label htmlFor='guestCanChooseRoomType'>Guest can choose room type?</label>
                            <Form.Check type='checkbox' name='guestCanChooseRoomType' id='guestCanChooseRoomType' defaultChecked={props.booking.guestCanChooseRoomType} />
                        </div>  
                        {props.booking.type=='Guest' ?  
                        <div className='form-row'>
                            <label>Registration form:</label>
                            <Select options={props.registrationFormTypes} defaultValue={()=>props.registrationFormTypes.find(ps=>ps.value==props.booking.registrationFormType)} onChange={(ps)=>setSelectedRegistrationFormType(ps.value)}/>
                        </div>:<div></div>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}