import { Fragment, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import config from "../config.js";

export default function TakeCardPaymentsModal(props) {
    
    const [show, setShow] = useState(true);
    const [started, setStarted] = useState(false);
    const [finished, setFinished] = useState(false);
    
    var index = 0;
    var chargeMessages = [];

    const close = () => {
        setShow(false);
        props.onCancel();
    }

    const takeNextPayment = () => {
        const bookingId = props.bookingIds[index];
        const amount = props.amounts[index];

        fetch(`${config.server_base_url}/api/bookings/${bookingId}/takePayment`, 
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                amount: amount
              })
            })
            .then((response) => response.json())
            .then((data) => { 
                            if (data.success) addChargeMessage(`<p>The card for booking ${bookingId} was charged for €${amount}.</p>`)
                            else addChargeMessage(`<p class='error'>We could not charge the card for booking ${bookingId}.</p>`);
                            index++;
                            if (index<props.bookingIds.length && show && !finished) {
                                setTimeout(takeNextPayment, 700);
                                if (index%10==0) props.onConfirm(); 
                            } else {
                                addChargeMessage('<p>Done</p>');
                                setFinished(true);
                                props.onConfirm();
                            }
                        })
            .catch((error) => addChargeMessage(`<p class='error'>We were unable to charge the card for booking ${bookingId}. We have stopped now.</p>`));
    }    

    const addChargeMessage = (msg) => {
        chargeMessages.push(msg);
        document.querySelector('.charge-messages').innerHTML = document.querySelector('.charge-messages').innerHTML + msg;
    }

    useEffect(()=> {
        if (started && index==0) {
            takeNextPayment();
        }
    }, [started]);

    return (
        <Modal show={show} onHide={close} backdrop='static' className='take-card-payments-modal'>
            <Modal.Header closeButton>
            <Modal.Title>Take Card Payments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!started ?
                    <p className='align-center'>You are about to charge {props.bookingIds.length} cards.<br/><br/> <Button variant="primary" onClick={()=>setStarted(true)}>Charge cards now</Button></p> 
                    :
                    <p className='align-center'>Currently charging cards. <br/><em>Closing this window will stop this process.</em></p>
                }
                <div className='charge-messages'></div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={() => {
                close();
                props.onConfirm();
            }}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    );
}