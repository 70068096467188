import dayjs from 'dayjs';
import { Fragment, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import config from "../config.js";
import { genericErrorMsg } from "../utils.js";
import Select from 'react-select';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';

export default function AddRoomModal(props) {
    
    const [errorMsg, setErrorMsg] = useState(null);

    const [locations, setLocations] = useState([]);
    const [roomTypes, setRoomTypes] = useState([]);
    const [rooms, setRooms] = useState([]);

    const [selectedRoomType, setSelectedRoomType] = useState(props.roomTypeId);
    const [selectedLocation, setSelectedLocation] = useState(props.locationId);
    const [selectedChildRoomIds, setSelectedChildRoomIds] = useState(props.childRoomIds);

    const fetchRoomTypes = () => {
        return fetch(`${config.server_base_url}/api/roomTypes`)
            .then((response) => response.json())
            .then((data) => {
                setRoomTypes(data.roomTypes.map(rt=>{return {value: rt.id, label: rt.name}}));
                if (selectedRoomType==null) setSelectedRoomType(data.roomTypes[0].id);
            });
    }

    const fetchLocations = () => {
        return fetch(`${config.server_base_url}/api/locations`)
            .then((response) => response.json())
            .then((data) => {
                setLocations(data.locations.map(loc=>{return {value: loc.id, label: loc.name}}));
                if (selectedLocation==null) setSelectedLocation(data.locations[0].id);
            });
    }

    const fetchRooms = () => {
        return fetch(`${config.server_base_url}/api/rooms`)
            .then((response) => response.json())
            .then((data) => {
                setRooms(data.rooms.filter(r=>r.id!=props.roomId).map(room=>{return {value: room.id, label: room.name}}));
            });
    }    

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.add-room-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {
            fetch(`${config.server_base_url}/api/rooms` + (props.roomId ? `/${props.roomId}`: ''), 
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                name: document.getElementsByName('name')[0].value,
                isBookable: document.getElementsByName('isBookable')[0].checked,
                roomTypeId: selectedRoomType,
                locationId: selectedLocation,
                childRoomIds: selectedChildRoomIds
              })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    const filterRooms = (roomOp, text) => {
        if (text.length==0) return rooms;
        const words = text.split(' ');
        return !(words.map(w=>roomOp.label.toLowerCase().includes(w.toLowerCase())).includes(false));
    }; 

    useEffect(()=> {
        document.getElementsByName('name')[0].focus();
        fetchRoomTypes();
        fetchLocations();
        fetchRooms();
    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='add-room-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{props.roomId ? 'Update' : 'Add'} Room</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='add-room-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div>
                            <label>Name:</label>
                            <Form.Control type="text" required name='name' defaultValue={props.roomName} />
                        </div>
                        <div>
                            <label>Room Type:</label>
                            {roomTypes.length ?
                            <Select options={roomTypes} defaultValue={()=>roomTypes.find(rt=>rt.value==props.roomTypeId) || roomTypes[0]} onChange={(roomType)=>setSelectedRoomType(roomType.value)}/>                        
                            : <Fragment />}
                        </div>
                        <div>
                            <label>Location:</label>
                            {locations.length ?
                            <Select options={locations} defaultValue={()=>locations.find(rt=>rt.value==props.locationId) || locations[0]} onChange={(location)=>setSelectedLocation(location.value)}/>                        
                            : <Fragment />}
                        </div>                        
                        <div>
                            <label htmlFor='isBookable'>Bookable:</label>
                            <div className='form-field-info'>Whether the system can assign this room automatically to a guest.</div>
                            <Form.Check type='checkbox' name='isBookable' id='isBookable' defaultChecked={props.roomIsBookable} />
                        </div>
                        <div>
                            <label>Beds:</label>
                            <div className='form-field-info'>When creating a double hut, select here the individual beds so that the system can use this to manage room availability.</div>
                            {rooms.length ?
                            <Select options={rooms} isMulti defaultValue={()=>rooms.filter(r=>props.childRoomIds?.includes(r.value))} onChange={(rooms)=>setSelectedChildRoomIds(rooms.map(r=>r.value))} filterOption={(candidate, input)=>filterRooms(candidate, input)}/>                        
                            : <Fragment />}                            
                        </div>                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}