import React, { useEffect, useState, useMemo, Fragment } from "react";
import Form from 'react-bootstrap/Form';
import config from "../config";
import { genericErrorMsg } from "../utils.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export default function WelcomeMyBooking(props) {

  const [html, setHtml] = useState(null);

  const fetchSettings = () => {
    fetch(`${config.server_base_url}/api/settings?names[]=${props.isVillageBooking ? 'villageBookingWelcomePageHtml' : 'bookingWelcomePageHtml'}`)
        .then((response) => response.json())
        .then((data) => setHtml(data.settings[0].value))
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }
  useEffect(() => {
      fetchSettings();
  },[])

  return (
    <div className='welcome-my-booking'>
      {html ? <div dangerouslySetInnerHTML={{__html: html}}></div> : <span></span>}
      <Form noValidate className='welcome-my-booking-form'>
        <Form.Check type='checkbox' id='privacyPolicyCheck' required label={<Fragment>I have read and agree to the <a href='https://documents.mooji.org/PP_Reception.pdf' target='_blank'>Privacy Policy</a></Fragment>} />
        <Form.Check type='checkbox' id='termsConditionsCheck' required label={<Fragment>I have read and agree to the <a href='https://documents.mooji.org/TC_Reception.pdf' target='_blank'>Terms and Conditions</a></Fragment>} />
      </Form>
      <ToastContainer />
    </div>
  );
}