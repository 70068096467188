import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { push as DesktopMenu } from 'react-burger-menu';
import { slide as MobileMenu } from 'react-burger-menu';
import config from '../config';
import { isAdmin, isReception, isSuperAdmin, isHousekeeping, isFinance } from '../utils';

function NavItem({label, active, onClick, menuLevel=1}) {
  const className = `l${menuLevel}` + (active ? ' active': '');
  return (<a onClick={onClick} className={className}>{label}</a>)
}

const Menu = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
      document.body.classList.toggle('menu', window.innerWidth>768)
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;

  return (isMobile ? 
    <MobileMenu {...props} isOpen={false}>{props.children}</MobileMenu> : 
    <DesktopMenu {...props} noOverlay>{props.children}</DesktopMenu>
  );
}

export default function Nav({url}) {

  let navigate = useNavigate();
  const [activeUrl, setActiveUrl] = useState(url);

  function navigateTo(newUrl) {
    navigate(newUrl, { replace: false });
    setActiveUrl(newUrl);
  }
  
  return (
    <Fragment>
      <Menu width={'var(--nav-width)'} pageWrapId={"page-container"} outerContainerId={"app-container"} isOpen disableCloseOnEsc disableAutoFocus noTransition onStateChange={(state) => document.body.classList.toggle('menu', state.isOpen)}>
          <Fragment>
            <a className='logo' onClick={() => navigateTo('/calendar')}><img src={`${config.server_base_url}/static/img/nivas-logo-white.png`}/> </a>
          </Fragment>
          <NavItem label='Calendar' active={activeUrl==='/calendar'} onClick={() => navigateTo('/calendar')} />
          {isAdmin() || isSuperAdmin() || isReception() || isHousekeeping() || isFinance() ? 
          <Fragment>
            <NavItem label='Payments Due' active={activeUrl==='/payments'} onClick={() => navigateTo('/payments')} />
            <NavItem label='Rooms' active={activeUrl==='/rooms'} onClick={() => navigateTo('/rooms')} />
            <NavItem label='Room Types' active={activeUrl==='/roomtypes'} onClick={() => navigateTo('/roomtypes')} menuLevel={2} />
            <NavItem label='Locations' active={activeUrl==='/locations'} onClick={() => navigateTo('/locations')} menuLevel={2} />
            <NavItem label='Rates' active={activeUrl==='/rates'} onClick={() => navigateTo('/rates')} />
            <NavItem label='Reports' active={activeUrl==='/reports'} onClick={() => navigateTo('/reports')} />
            {isAdmin() || isSuperAdmin() || isReception() ?
            <NavItem label='Settings' active={activeUrl==='/settings'} onClick={() => navigateTo('/settings')} /> : <Fragment />}
          </Fragment> : <Fragment />}
          <Fragment>
            <a className="l1" href={`${config.server_base_url}/logout`}>Logout</a>
          </Fragment>
      </Menu>
    </Fragment>
  )
}