import dayjs from 'dayjs';
import { Fragment, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import config from "../config.js";
import { genericErrorMsg } from "../utils.js";
import Select from 'react-select';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';

export default function AddLocationModal(props) {
    
    const [errorMsg, setErrorMsg] = useState(null);

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.add-location-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {
            fetch(`${config.server_base_url}/api/locations` + (props.locationId ? `/${props.locationId}`: ''), 
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                name: document.getElementsByName('name')[0].value
              })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    useEffect(()=> {
        document.getElementsByName('name')[0].focus();
    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='add-location-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{props.locationId ? 'Update' : 'Add'} Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='add-location-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div>
                            <label>Name:</label>
                            <Form.Control type="text" required name='name' defaultValue={props.locationName} />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}