import React, { Fragment, useEffect, useState } from "react";

import Nav from "../components/Nav.js";
import config from "../config.js";
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import Table from 'react-bootstrap/Table';
import AddPaymentModal from "../components/AddPaymentModal.js";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import TakeCardPaymentsModal from "../components/TakeCardPaymentsModal.js";
import LoadingSpinner from "../components/LoadingSpinner.js";

export default function PaymentsDuePage() {

    const [duePaymentsUntil, setDuePaymentsUntil] = useState(dayjs().startOf('month').toDate());
    const [bookings, setBookings] = useState(null);
    
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [showChargeCardsModal, setShowChargeCardsModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);

    const [loading, setLoading] = useState(false);
        
    const [chargingType, setChargingType] = useState('Team');
    
    const cardOnFileFilterOptions = ['w card', 'w/o card'].map((p, index)=>{return {value: index, label: p}});
    
    const fetchBookings = (date) => {
        setLoading(true);
        return fetch(`${config.server_base_url}/api/bookings?` + new URLSearchParams({
            filterDuePaymentsUntil: dayjs(date).format('YYYY-MM-DD')
          }))
            .then((response) => response.json())
            .then((data) => setBookings(data.bookings))
            .then(()=>setLoading(false));
    }

    const getSelectedBookingIds = () => {
        return [...document.querySelectorAll(`${chargingType=='Team' ? '.team-bookings' : (chargingType=='Guest' ? '.guest-bookings' : '.village-bookings')} .booking-check input:checked`)].map(ch=>ch.closest('tr').dataset.bookingid);
    }

    const getSelectedBookingAmounts = () => {
        return [...document.querySelectorAll(`${chargingType=='Team' ? '.team-bookings' : (chargingType=='Guest' ? '.guest-bookings' : '.village-bookings')} .booking-check input:checked`)].map(ch=>ch.closest('tr').dataset.amount);
    }

    const selectFilterStyles = { menuPortal: (base) => ({ ...base, fontSize: '14px', zIndex: 6 }), control: (base) => ({ ...base, fontSize: '14px', paddingTop: 0 }) };

    useEffect(() => {
        fetchBookings(duePaymentsUntil);
    }, [])

    return (
      <div id='app-container' className="app-container">
        <Nav url="/payments"></Nav>
        <div className='page-header'><h1>Payments Due</h1></div>
        <div id='page-container' className="page-container payments-due-page">  
            {/* <div className='form-row form-filters'>
                <label>Due payments until</label>
                <DatePicker selected={duePaymentsUntil} onChange={(date) => {setDuePaymentsUntil(date); fetchBookings(date)}} dateFormat="dd MMM, yyyy" calendarStartDay={1} />
            </div> */}
            <Tabs className="mb-3">
                {['Team', 'Guest', 'Village'].map((type, tabIndex)=> {

                const [chargeAllCardsEnabled, setChargeAllCardsEnabled] = useState(false);
                const [selectedCardFilterOptions, setSelectedCardFilterOptions] = useState([]);

                const selectedBookingsChanged = () => {                    
                    const checkedBookings = [...document.querySelectorAll(`.${type.toLowerCase()}-bookings .booking-check input:checked`)];
                    setChargeAllCardsEnabled(checkedBookings.length && (checkedBookings.find(ch=>ch.closest('tr').dataset.canchargecard=='false')==null));                                                    
                }

                return <Tab key={tabIndex} eventKey={`${type}Bookings`} title={`${type} Bookings`}>
                    {type=='Team' ? <p className='tab-info-text'>Team bookings with an outstanding balance up until <span>{dayjs(duePaymentsUntil).format('DD MMM, YYYY')}</span> will appear here.</p> : <Fragment />}
                    {type=='Guest' ? <p className='tab-info-text'>Guest bookings with an outstanding balance and an arrival date before today will appear here.</p> : <Fragment />}
                    {type=='Village' ? <p className='tab-info-text'>Village bookings with an outstanding balance and an arrival date before today will appear here.</p> : <Fragment />}
                    {loading ? <LoadingSpinner /> : <Fragment />}
                    {bookings ? 
                        (bookings.filter(b=>b.type==type).length ? 
                            <div className='data-table-container'>
                                <div className='table-actions table-filters'>
                                    <label className='filter-group'>Filter bookings:</label>
                                    <Select options={cardOnFileFilterOptions} placeholder="w and w/o card" onChange={(ops)=>setSelectedCardFilterOptions(ops.map(op=>op.value))} isMulti menuPortalTarget={document.body} styles={selectFilterStyles} />
                                    <div style={{flex: '1 1 0px'}} />
                                    <label className='filter-group'>With selected:</label>
                                    <Button variant="primary" disabled={!chargeAllCardsEnabled} onClick={()=>{setChargingType(type); setShowChargeCardsModal(true) }}>Charge cards now</Button>
                                </div>
                                <Table striped bordered hover className={`payments-due-bookings-table ${type.toLowerCase()}-bookings`}>
                                    <thead>
                                        <tr>
                                            <td><Form.Check type='checkbox' name={`${type.toLowerCase()}-booking-all`} id={`${type.toLowerCase()}-booking-all`} onChange={(e)=>
                                            {
                                                [...document.querySelectorAll(`.${type.toLowerCase()}-bookings .booking-check input`)].forEach(c=>c.checked=e.target.checked);
                                                selectedBookingsChanged();
                                            }} /></td>
                                            <td>Booking</td>
                                            <td>Guest</td>
                                            <td>Type</td>
                                            <td>Paid until</td>
                                            <td>Next payment</td>
                                            <td>For period</td>
                                            <td>Card on file</td>
                                            <td>Actions</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bookings.filter(b=>b.type==type).filter(b=> {
                                            const cardOnFile = b.guest.stripeCustomerIdKnown && b.stripePaymentMethodIdKnown;
                                            return selectedCardFilterOptions.length==0
                                            ||
                                            (selectedCardFilterOptions.includes(0) && cardOnFile)
                                            ||
                                            (selectedCardFilterOptions.includes(1) && !cardOnFile)}).map((booking, index) => (
                                            <tr key={booking.id} data-bookingid={booking.id} data-canchargecard={booking.guest.stripeCustomerIdKnown && booking.stripePaymentMethodIdKnown ? true : false} data-amount={booking.nextPayment?.amount}>
                                                <td><Form.Check type='checkbox' className='booking-check' name={`booking${booking.id}`} onChange={(e)=>{document.querySelector('#team-booking-all').checked=document.querySelectorAll('.booking-check input:checked').length==document.querySelectorAll('.booking-check input').length; selectedBookingsChanged();}} /></td>
                                                <td><a href={`/bookings/${booking.id}`}>{booking.id}</a></td>
                                                <td>{`${booking.guest.preferredName} ${booking.guest.lastName.slice(0,3)}.`}</td>
                                                <td>{booking.type}</td>
                                                <td>{dayjs(booking.paidUntil).format('DD MMM YYYY')}</td>
                                                <td>{booking.nextPayment ? `€${booking.nextPayment.amount}` : ''}</td>
                                                <td>{booking.nextPayment ? `${dayjs(booking.nextPayment.startDate).format('DD MMM')} - ${dayjs(booking.nextPayment.endDate).format('DD MMM')}` : ''}</td>
                                                <td>{booking.guest.stripeCustomerIdKnown && booking.stripePaymentMethodIdKnown ? <Fragment>yes {booking.payments && booking.payments.length && booking.payments[booking.payments.length-1].lastPaymentErrorMsg ? <span className='card-payment-failed-msg'><br/>Last payment<br/> attempt failed</span> : ''}</Fragment> : 'no'}</td>
                                                <td>
                                                    {booking.balance<0 ? <Button onClick={()=>{setSelectedBooking(booking);setShowAddPaymentModal(true);}}>Add payment</Button> : <Fragment />}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div> : <p>No due payments</p>)
                        : <span></span>}
                </Tab>})
                }
            </Tabs>
            {showAddPaymentModal ? <AddPaymentModal bookingId={selectedBooking.id} refund={false} onCancel={()=>setShowAddPaymentModal(false)} onSave={()=>fetchBookings(duePaymentsUntil)} /> : <span></span>}
            {showChargeCardsModal ? <TakeCardPaymentsModal bookingIds={getSelectedBookingIds()} amounts={getSelectedBookingAmounts()} onCancel={()=>setShowChargeCardsModal(false)} onConfirm={()=>fetchBookings(duePaymentsUntil)} /> : <span></span>}
        </div>
      </div>
    )
  }