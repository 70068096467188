import React, { useState, useEffect, Fragment } from "react";
import Nav from "../components/Nav.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import BookingsCalendar from "../components/BookingsCalendar.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Select, { createFilter } from 'react-select';
import config from "../config.js";
import {bookingToString, isSahajaBhavan, isMobile} from "../utils.js";
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import * as _ from "lodash/array";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';

export default function BookingsPage() {

    dayjs.extend(weekday);

    const [searchParams, setSearchParams] = useSearchParams();

    const [startDate, setStartDate] = useState(()=> searchParams.get('startDate') ? dayjs(searchParams.get('startDate')).toDate() : dayjs().subtract(5, 'day').toDate());
    const [endDate, setEndDate] = useState(()=> searchParams.get('endDate') ? dayjs(searchParams.get('endDate')).toDate() : dayjs().add(42, 'day').toDate());

    const [calendarPeriods, setCalendarPeriods] = useState([]);

    const calendarViews = ['Day', 'Week'].map(p=>{return {value: p, label: `${p} view`}});
    const roomIsBookableOptions = ['Bookable by guests'].map(p=>{return {value: p, label: p}});
    const roomStatusOptions = ['Free', 'Blocked', 'Confirmed', 'Needs cleaning/preparing within 3 days'].map(p=>{return {value: p, label: p}});

    const [selectedLocations, setSelectedLocations] = useState(() => searchParams.get('locs') ? searchParams.get('locs').split('|').map(l=>parseInt(l)) : []);
    const [selectedRoomTypes, setSelectedRoomTypes] = useState(() => searchParams.get('rts') ? searchParams.get('rts').split('|').map(l=>parseInt(l)) : []);
    const [selectedIsBookable, setSelectedIsBookable] = useState(() => searchParams.get('isb') && searchParams.get('isb')=='true' ? roomIsBookableOptions[0].value : null);
    const [selectedRoomStatuses, setSelectedRoomStatuses] = useState(() => searchParams.get('rsts') ? searchParams.get('rsts').split('|') : []);
    const [selectedView, setSelectedView] = useState(() => searchParams.get('view') && searchParams.get('view')=='Week' ? 'Week' : 'Day');
    const [hkOn, setHkOn] = useState(() => searchParams.get('hk') && searchParams.get('hk')=='true' ? true : false);
    const [firstCalendarPaint, setFirstCalendarPaint] = useState(true);

    const [locations, setLocations] = useState([]);
    const [bookings, setBookings] = useState(null);
    const [roomTypes, setRoomTypes] = useState([]);

    var wheelLeftCount = 0;
    var wheelRightCount = 0;

    let navigate = useNavigate();
    
    const fetchBookings = () => {
      fetch(`${config.server_base_url}/api/bookings`)
          .then((response) => response.json())
          .then((data) => setBookings(data.bookings));
    }  
    
    const fetchRooms = () => {
      fetch(`${config.server_base_url}/api/roomTypesForCalendar?` + new URLSearchParams({
        rbStartDate: (selectedView =='Day' ? dayjs(startDate) : dayjs(startDate).weekday(1)).format('YYYY-MM-DD'),
        rbEndDate: (selectedView =='Day' ? dayjs(endDate) : dayjs(endDate).weekday(7)).format('YYYY-MM-DD')
      }))
      .then((response) => response.json())
      .then((data) => {
        setRoomTypes(data.roomTypes)
        if (!locations.length) setLocations(_.uniqBy(data.roomTypes.flatMap(rt=>rt.locations.map(l=>{return {value: l.id, label: l.name}})), 'value').sort((l1, l2)=>l1.label.localeCompare(l2.label)))
      });
    }

    const fetchSettings = () => {
      fetch(`${config.server_base_url}/api/settings?names[]=calendarPeriods`)
          .then((response) => response.json())
          .then((data) => setCalendarPeriods(data.settings.length ? JSON.parse(data.settings[0].value).map(cp=>{return {name: cp.name, startDate: dayjs(cp.startDate).toDate(), endDate: dayjs(cp.endDate).toDate()}}) : []))
          .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
    }

    function BookingsView() {
      return <BookingsCalendar 
                startDate={selectedView =='Day' ? startDate : dayjs(startDate).weekday(1).toDate()} 
                endDate={selectedView =='Day' ? endDate : dayjs(endDate).weekday(7).toDate()} 
                bookings={bookings} 
                roomTypes={roomTypes} 
                locationsFilter={selectedLocations} 
                roomTypesFilter={selectedRoomTypes}
                isBookableFilter={selectedIsBookable!=null}
                isFreeFilter={selectedRoomStatuses.includes('Free')}
                isBlockedFilter={selectedRoomStatuses.includes('Blocked')}
                isConfirmedFilter={selectedRoomStatuses.includes('Confirmed')}
                isHKFilter={selectedRoomStatuses.includes('Needs cleaning/preparing within 3 days')}
                selectedView={selectedView}
                hk={hkOn}
                firstCalendarPaint={firstCalendarPaint}
                onChange={()=> {setFirstCalendarPaint(false); fetchRooms(); fetchBookings(); }}
              />;
    }

    const selectFilterStyles = { menuPortal: (base) => ({ ...base, fontSize: '14px', zIndex: 6 }), control: (base) => ({ ...base, fontSize: '14px', paddingTop: 0 }) };

    const handleCalendarScroll = (e) => {
      const scrollable = document.getElementById('page-container');
      const scrollLeft = scrollable.scrollLeft;

      if (e.deltaX>=0) wheelLeftCount = 0;
      if (e.deltaX<=0) wheelRightCount = 0;

      var scrollingLeft = false;
      var scrollingRight = false;
      if (scrollLeft + scrollable.offsetWidth>=scrollable.scrollWidth) {
        if (e.deltaX>0) wheelRightCount++;
        if (wheelRightCount==60) scrollingRight = true;
      }

      if (scrollLeft==0) {
        if (e.deltaX<0) wheelLeftCount++;
        if (wheelLeftCount==160) scrollingLeft = true;
      }

      if (scrollingLeft || scrollingRight) {
        setFirstCalendarPaint(false);
        if (scrollingLeft) setStartDate(dayjs(startDate).subtract(14, 'day').toDate());
        if (scrollingRight) setEndDate(dayjs(endDate).add(14, 'day').toDate());

        wheelLeftCount = 0;
        wheelRightCount = 0;
      }      
    }

    useEffect(() => {
      fetchBookings();
    },[])

    useEffect(() => {
      setSearchParams(`?startDate=${dayjs(startDate).format('YYYY-MM-DD')}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}&locs=${selectedLocations.join('|')}&rts=${selectedRoomTypes.join('|')}&isb=${selectedIsBookable!=null}&rsts=${selectedRoomStatuses.join('|')}&hk=${hkOn}&view=${selectedView}`)
    },[startDate, endDate, selectedLocations, selectedRoomTypes, selectedIsBookable, selectedRoomStatuses, hkOn, selectedView])    

    useEffect(() => {
      fetchRooms();
      fetchSettings();
    },[startDate, endDate])

    return (
      <div id="app-container" className="bookings-app app-container">
          <Nav url="/calendar"></Nav>
          <div className="page-toolbar">
            <div className='action-buttons'>
              <Button variant="primary" onClick={()=> navigate("/bookings/edit", { replace: false })} className='mb-2' disabled={isSahajaBhavan()}>Create Booking</Button>
            </div>
            <div className="search-booking">
              <Select onChange={(b)=>navigate(`/bookings/${b.value}`)} options={bookings ? bookings.map(booking=>{ return {value: booking.id, label: bookingToString(booking) }}) : []} placeholder="Jump to booking..." menuPortalTarget={document.body} autoFocus={!isMobile()} styles={{ menuPortal: (base) => ({ ...base, zIndex: 7 }) }} components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }} />
            </div>
            <div className="date-pickers">
              From: <DatePicker selected={startDate} onChange={(date) => {setFirstCalendarPaint(false); setStartDate(date); if (dayjs(date).isAfter(endDate)) setEndDate(dayjs(date).add(42, 'day').toDate());} } dateFormat="dd MMM, yyyy" calendarStartDay={1} />
              To: <DatePicker selected={endDate} minDate={startDate} onChange={(date) => {setFirstCalendarPaint(false); setEndDate(date)}} dateFormat="dd MMM, yyyy" calendarStartDay={1} />
            </div>
            {!isSahajaBhavan() ? 
            <div className="date-filters">
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle variant="link" id="dropdown-basic">Periods</Dropdown.Toggle>
                <Dropdown.Menu>
                  {calendarPeriods.map((period, index)=>
                    <Dropdown.Item key={index} onClick={()=>{setStartDate(period.startDate);setEndDate(period.endDate);}}>{`${dayjs(period.startDate).format('DD MMM')} - ${dayjs(period.endDate).format('DD MMM')} ${period.name}`}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div> : <Fragment />}
          </div>
          <div className="page-filters">
            <label className='filter-group'>Filter rooms:</label> 
            <div className="location-filter">
              <Select options={locations} value={locations.filter(l=>selectedLocations.includes(l.value))} onChange={(locs) => (async ()=>setSelectedLocations(locs.map(l=>l.value)))()} placeholder="All locations" isMulti menuPortalTarget={document.body} styles={selectFilterStyles}/>
            </div>
            <div className="room-type-filter">
              <Select options={roomTypes.map(rt=>{return {value: rt.id, label: rt.name}})} value={roomTypes.map(rt=>{return {value: rt.id, label: rt.name}}).filter(l=>selectedRoomTypes.includes(l.value))} onChange={(rts) => (async () => setSelectedRoomTypes(rts.map(l=>l.value)))()} placeholder="All room types" isMulti menuPortalTarget={document.body} styles={selectFilterStyles}/>
            </div>
            <div className="room-bookable-filter">
              <Select options={roomIsBookableOptions} value={roomIsBookableOptions.filter(l=>selectedIsBookable==l.value)} onChange={(ib) => (async () => setSelectedIsBookable(ib.length>0 ? ib[0].value : null))()} isMulti placeholder="Team & Guest" menuPortalTarget={document.body} styles={selectFilterStyles}/>
            </div>
            <div className="room-status-filter">
              <Select options={roomStatusOptions} value={roomStatusOptions.filter(l=>selectedRoomStatuses.includes(l.value))} onChange={(sts) => (async ()=>setSelectedRoomStatuses(sts.map(s=>s.value)))()} isMulti placeholder="Free and Booked" menuPortalTarget={document.body} styles={selectFilterStyles}/>
            </div> 
            <div className="hk-toggle">
              <label className='toggle'>
                <input type="checkbox" defaultChecked={hkOn} onClick={()=>{setFirstCalendarPaint(false); setHkOn(!hkOn)}} />
                <span></span>
                <strong>HK</strong>
              </label>
            </div>
            <div className="calendar-period">
              <Select options={calendarViews} onChange={(p) => (async ()=>{setFirstCalendarPaint(false); setSelectedView(p.value)})()} defaultValue={calendarViews.find(v=>v.value==selectedView)} menuPortalTarget={document.body} styles={selectFilterStyles}/>
            </div>
          </div>       
  
          <div id='page-container' className="page-container bookings-page" onWheel={handleCalendarScroll}>
            {bookings && roomTypes.length ? <BookingsView /> : <Fragment />}
          </div>
      </div>
    )
  }