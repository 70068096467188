import { Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import config from "../config.js";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { genericErrorMsg } from "../utils.js";

export default function ChangeCleaningStatusModal(props) {
    
    dayjs.extend(utc);

    const [errorMsg, setErrorMsg] = useState(null);

    const cleaningStatusOptions = ['dirty', 'clean', 'prepared'].map((st)=> { return {value: st, label: st.toUpperCase()}})

    const [cleaningStatus, setCleaningStatus] = useState(props.room.cleaningStatus);
    const [cleaningStatusUpdatedAt, setCleaningStatusUpdatedAt] = useState(dayjs.utc().toDate());

    const save = (e) => {
        e.preventDefault();

        fetch(`${config.server_base_url}/api/rooms/${props.room.id}/updateCleaningStatus`, 
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                cleaningStatus: cleaningStatus,
                cleaningStatusUpdatedAt: dayjs(cleaningStatusUpdatedAt).format('YYYY-MM-DD')
            })          
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
        .catch((error) => setErrorMsg(genericErrorMsg));
    }

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='change-cleaning-status-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Change Cleaning Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate className="change-cleaning-status-form" onSubmit={save}>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div className='form-row'>
                            <label>Cleaning Status for <em>{props.room.name}</em>:</label>
                            <Select options={cleaningStatusOptions} defaultValue={()=>cleaningStatusOptions.find(ps=>ps.value==props.room.cleaningStatus)} onChange={(cs)=>setCleaningStatus(cs.value)} />
                        </div>
                        <div className='form-row'>
                            <label>Since:</label>
                            <DatePicker selected={cleaningStatusUpdatedAt} onChange={(date) => {setCleaningStatusUpdatedAt(date)}} dateFormat="dd MMM" calendarStartDay={1} onKeyDown={(e) => { e.preventDefault();}} />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}