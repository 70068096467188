import React, { useEffect, useState, Fragment } from "react";

import config from "../config.js";
import { useSearchParams } from 'react-router-dom';
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "../components/CheckoutForm.js";
import WelcomeMyBooking from "../components/WelcomeMyBooking.js";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { genericErrorMsg } from "../utils.js";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import LoadingSpinner from "../components/LoadingSpinner.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const stripePromise = loadStripe(config.stripe_public_key);

export default function OpenBookingPage() {

    const [searchParams, setSearchParams] = useSearchParams();
    const property = searchParams.get('p');
    const rateGroups = searchParams.getAll('rg');
    const token = searchParams.get('tk');
    const payCash = searchParams.get('pc');

    let navigate = useNavigate();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [showRoomTypeRates, setShowRoomTypeRates] = useState(null);
    const [isPayCash, setIsPayCash] = useState(false);

    const [clientSecret, setClientSecret] = useState(null);
    const [paymentIntentId, setPaymentIntentId] = useState(null);
    const [nextPayment, setNextPayment] = useState(null);
    const [selectedRate, setSelectedRate] = useState(null);
    
    const [step, setStep] = useState(1);

    const [loading, setLoading] = useState(false);

    const fetchAvailability = () => {
        resetSearch();
        setLoading(true);
        const searchParams = {
            tk: token,
            property: property,
            pc: payCash,
            rateGroups: rateGroups,
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            endDate: dayjs(endDate).format('YYYY-MM-DD')
        };

        fetch(`${config.server_base_url}/api/open-booking/checkAvailability?` + new URLSearchParams(searchParams))
            .then((response) => response.json())
            .then((data) => {
                setLoading(false);
                setShowRoomTypeRates(data.showRoomTypeRates);
                setIsPayCash(data.isPayCash ? data.isPayCash : false);
                if (data.showRoomTypeRates.length==1) {
                    const rateId = data.showRoomTypeRates[0].id;
                    const ratePrice = data.showRoomTypeRates[0].rate.price;
                    setSelectedRate(data.showRoomTypeRates[0]);
                    if (ratePrice!=0) fetchStripeSecret(rateId);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                toast.error( genericErrorMsg, {theme: 'colored'})
            }); 
    }

    const fetchStripeSecret = (rateId) => {
        fetch(`${config.server_base_url}/api/open-booking/create-payment-intent`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    property: property,
                    rateId: rateId,
                    startDate: dayjs(startDate).format('YYYY-MM-DD'),
                    endDate: dayjs(endDate).format('YYYY-MM-DD')
                })
            })
            .then((response) => response.json())
            .then((data) => {
                setNextPayment(data.nextPayment)
                setPaymentIntentId(data.paymentIntentId);
                setClientSecret(data.clientSecret)
            });
    }

    const maybeGoToStep = (step) => {
        if (step==2) {//validate welcome form
            const welcomeForm = document.querySelector('form.welcome-my-booking-form');
            welcomeForm.classList.add('was-validated');

            if (welcomeForm.checkValidity()===true) {
                setStep(2); scrollToTop();
            }
        }
    }

    const changeSelectedRate = (rate, ratePrice)=> {
        setSelectedRate(rate);

        if (ratePrice!=0) fetchStripeSecret(rate.id);
        else setNextPayment({amount: 0});
    }

    const roomTypeRateDisplayName = (rtRate) => {
        return `${rtRate.roomType.name}
                    ${rtRate.occupancyChoice && rtRate.singleOccupancy ? ' - One Guest Only' : ''}
                    ${rtRate.occupancyChoice && rtRate.doubleOccupancy ? ' - Two Guests' : ''}`;
    }

    const bookingDurationDays = ()=>dayjs(endDate).startOf('day').diff(dayjs(startDate).startOf('day'), 'day')

    const resetSearch = () => {
        setSelectedRate(null); 
        setClientSecret(null); 
        setShowRoomTypeRates(null); 
        setNextPayment(null); 
        setPaymentIntentId(null);
    }

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance
    };

    const scrollToTop = () => {
        setTimeout(function() {window.scrollTo(0, 0);}, 10)
    }

    return (
    <div id='app-container' className="app-container my-booking-app open-booking-app">
        <div id='page-container' className="page-container my-booking-page with-page-header open-booking-page">  
            <div className='page-header'>
                <img className='mooji-logo' src="https://cdn.mooji.org/wp-content/themes/moojiorg/img/logo2.png" srcSet="https://cdn.mooji.org/wp-content/themes/moojiorg/img/logo2.png 1x, https://cdn.mooji.org/wp-content/themes/moojiorg/img/logo2@2x.png 2x" alt="Mooji" title="Mooji" />
            </div>
            <div>
                <ul className="progressbar">
                    {['Start', 'Accommodation', 'Register', 'Done'].map((stepName, index) =>
                        <li key={index} className={step==index+1 ? 'active': ''}><span>{stepName}</span></li>
                    )}
                </ul>
            </div>
            { step==1 ?
                <div key={step} className='booking-form welcome-form'>
                    <h1>WELCOME TO SÃO MARTINHO DAS AMOREIRAS</h1>
                    <WelcomeMyBooking isVillageBooking={true} />
                    <div className='action-buttons'>
                        <Button variant="primary" onClick={()=>maybeGoToStep(2)} className='mb-2'>Continue</Button>
                    </div>
                </div> : <span></span>
            }
            { step==2 ? 
            <div key={step} className='booking-form'>
                <h1>YOUR ACCOMMODATION</h1>
                <h4 style={{textAlign: 'center'}}>Please choose your dates:</h4>
                <div className='form-row dates-row'>
                    <div>
                        <label>Arrival Date</label>
                        <DatePicker name='startDate' placeholderText="Pick date" selected={startDate} onChange={(date) => {setStartDate(date); if (dayjs(date).isAfter(endDate)) setEndDate(dayjs(date).add(3,'day').toDate()); }} dateFormat="dd MMM, yyyy" calendarStartDay={1} onKeyDown={(e) => { e.preventDefault();}} minDate={dayjs().toDate()}/>
                    </div>
                    <div>
                        <label>Departure Date</label>
                        <DatePicker name='endDate' placeholderText="Pick date" selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} minDate={dayjs(startDate).add(1, 'day').toDate()} onKeyDown={(e) => { e.preventDefault();}} />
                    </div>
                    <div>
                        <Button variant='primary' disabled={startDate==null || endDate==null} onClick={()=>fetchAvailability()}>Check availability{loading ? <LoadingSpinner/> : ''}</Button>
                    </div>
                </div>

                {showRoomTypeRates && showRoomTypeRates.length==0 ? <h3 style={{textAlign: 'center'}}>No rooms are available for your dates. Please try different dates.</h3> : <h3></h3>}
                {showRoomTypeRates && showRoomTypeRates.length>1 ? <h3 style={{textAlign: 'center'}}>Please choose the type of room you would like to stay in:</h3> : <h3></h3>}
                <div className='form-row room-type-choice'>
                    {showRoomTypeRates && showRoomTypeRates.map((rtRate) =>
                        <div key={rtRate.id} className="room-type-card" onClick={(e)=>e.target.closest('.room-type-card').querySelector('input[type=radio]').click()}>
                            <div className="room-type-photo" onClick={(e)=>e.stopPropagation()}>
                                <ImageGallery showBullets={true} showPlayButton={false} items={(rtRate.roomType.photos && JSON.parse(rtRate.roomType.photos).length ? JSON.parse(rtRate.roomType.photos) : ['no_image.png']).map(p=>{
                                    return {
                                        original: `${config.server_base_url}/static/uploads/${p}`,
                                        //thumbnail: `${config.server_base_url}/static/uploads/${p}`
                                    }
                                })} />
                            </div>
                            <div className="room-type-info">
                                <h3>{roomTypeRateDisplayName(rtRate)}</h3>                                
                                {rtRate.occupancyChoice && rtRate.double ? <h4>Double occupancy</h4> : <Fragment />}
                                <div dangerouslySetInnerHTML={{ __html: rtRate.roomType.description }} />
                            </div>
                            <div className="room-type-price">
                                <Form.Check type='radio' id={rtRate.roomType.id + '-choice'}>
                                    <Form.Check.Input type='radio' isValid name='room-type-choice' value={rtRate.id} checked={rtRate.id==selectedRate?.id} onChange={(e)=>changeSelectedRate(rtRate, rtRate.rate.price)}  />
                                    <Form.Check.Label htmlFor={rtRate.roomType.id + '-choice'}>
                                        <h4>€{ rtRate.rate.price } / night</h4>
                                    </Form.Check.Label>                                    
                                </Form.Check>
                            </div>
                        </div>
                    )}
                </div>                
                {nextPayment && (clientSecret || nextPayment.amount==0) ? 
                    <div className='form-row'>
                        <div className='your-selection'>
                            <h2>Your selection</h2>
                            <p>{roomTypeRateDisplayName(selectedRate)}<br/>
                            <em><strong>{dayjs(startDate).format('DD MMM, YYYY')} - {dayjs(endDate).format('DD MMM, YYYY')} ({bookingDurationDays()} nights)</strong></em></p>
                            <h3>Total: <strong>€{nextPayment.amount}</strong></h3>
                        </div>

                        {isPayCash ? 
                            <div className='form-row'>
                                {nextPayment.amount>0 ? <h3>You can pay in person during your visit.</h3> : <h3></h3>}

                                <CheckoutForm payment={false} onBillingAddressSaved={(data)=>navigate(`/my-booking/${data.bookingId}?paymentCompleted=1`, { replace: false })} showSecondGuestFields={selectedRate.doubleOccupancy || !selectedRate.occupancyChoice} secondGuestFieldsRequired={selectedRate.doubleOccupancy && selectedRate.occupancyChoice} isOpenBooking={true} amount={nextPayment.amount} bookingType='Village' paymentIntentId={paymentIntentId} onSessionExpired={()=>resetSearch()} property={property} />
                            </div>
                            :
                            <Elements stripe={stripePromise} options={options} key={clientSecret}>
                                <CheckoutForm onBillingAddressSaved={(data)=>{}} showSecondGuestFields={selectedRate.doubleOccupancy || !selectedRate.occupancyChoice} secondGuestFieldsRequired={selectedRate.doubleOccupancy && selectedRate.occupancyChoice} isOpenBooking={true} amount={nextPayment.amount} bookingType='Village' paymentIntentId={paymentIntentId} onSessionExpired={()=>resetSearch()} returnUrl={`${config.server_base_url}/api/payments/completed`} property={property}/>
                            </Elements>
                        }
                    </div>:<span></span>}                          
            </div> : <span></span>}
        </div>
        <ToastContainer />
      </div>
    )
  }