import React, { useEffect, useState } from "react";

import Nav from "../components/Nav.js";
import config from "../config.js";
import { genericErrorMsg, isAdmin, isSuperAdmin } from "../utils.js";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {IoAddOutline} from "react-icons/io5";
import AddRoomModal from "../components/AddRoomModal.js";
import ConfirmationModal from "../components/ConfirmationModal.js";
import {MdMode, MdDelete} from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {BsCheckLg} from "react-icons/bs";
import Select, { createFilter } from 'react-select';

export default function RoomsPage() {

    const [rooms, setRooms] = useState([]);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [locations, setLocations] = useState([]);
    const [roomTypes, setRoomTypes] = useState([]);

    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedRoomTypes, setSelectedRoomTypes] = useState([]);

    const [selectedRoom, setSelectedRoom] = useState(null);

    const fetchRooms = () => {
        fetch(`${config.server_base_url}/api/rooms`)
            .then((response) => response.json())
            .then((data) => setRooms(data.rooms))
            .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
    }    

    const deleteRoom = (roomId) => {
        fetch(`${config.server_base_url}/api/rooms/${roomId}`, 
            {
              method: 'DELETE',
              headers: { 'Content-Type': 'application/json' },
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) fetchRooms() })
            .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
      }

    const fetchLocations = () => {
        fetch(`${config.server_base_url}/api/locations`)
            .then((response) => response.json())
            .then((data) => setLocations(data.locations.map(l=>{return {value: l.id, label: l.name}})));
    }  
            
    const fetchRoomTypes = () => {
        fetch(`${config.server_base_url}/api/roomTypes`)
            .then((response) => response.json())
            .then((data) => setRoomTypes(data.roomTypes.map(rt=>{return {value: rt.id, label: rt.name}})));
    }    
    
    const selectFilterStyles = { menuPortal: (base) => ({ ...base, fontSize: '14px', zIndex: 6 }), control: (base) => ({ ...base, fontSize: '14px', paddingTop: 0 }) };

    useEffect(() => {
        fetchRooms();
        fetchLocations();
        fetchRoomTypes();
    },[])

    return (
      <div id='app-container' className="app-container">
        <Nav url="/rooms"></Nav>
        <div className='page-header'><h1>Rooms</h1></div>
        <div id='page-container' className="page-container rooms-page">  
            <div className='data-table-container'>
                <div className='table-actions table-filters'>
                    <label className='filter-group'>Filter rooms:</label>
                    <div className="location-filter">
                        <Select options={locations} onChange={(locs) => (async ()=>setSelectedLocations(locs.map(l=>l.value)))()} isMulti placeholder="All locations" menuPortalTarget={document.body} styles={selectFilterStyles}/>
                    </div>
                    <div className="room-type-filter">
                        <Select options={roomTypes} onChange={(rts) => (async ()=>setSelectedRoomTypes(rts.map(rt=>rt.value)))()} isMulti placeholder="All room types" menuPortalTarget={document.body} styles={selectFilterStyles}/>
                    </div>
                    <div style={{flex: '1 1 0px'}} />
                    <Button variant="primary" disabled={!(isAdmin() || isSuperAdmin())} onClick={()=>{setSelectedRoom(null); setShowAddModal(true); }}><IoAddOutline /> Create</Button>
                </div>
                <Table striped bordered hover className='rooms-table'>
                    <thead>
                        <tr>
                            <td>Room</td>
                            <td>Type</td>
                            <td>Location</td>
                            <td>Bookable</td>
                            <td>Actions</td>
                        </tr>
                    </thead>

                    <tbody>
                        {rooms && rooms.length > 0 && rooms.filter(room=>
                            (selectedLocations.length==0 || selectedLocations.includes(room.locationId)) && (selectedRoomTypes.length==0 || selectedRoomTypes.includes(room.roomTypeId))).map((room) => (
                            <tr key={room.id}>
                                <td>{room.name}</td>
                                <td>{room.roomType.name}</td>
                                <td>{room.location.name}</td>
                                <td>{room.isBookable ? <BsCheckLg /> : ''}</td>
                                <td className='row-actions'>                                    
                                    <Button variant='light' onClick={()=> {setShowAddModal(true); setSelectedRoom({roomId: room.id, roomName: room.name, roomTypeId: room.roomTypeId, locationId: room.locationId, roomIsBookable: room.isBookable, childRoomIds: JSON.parse(room.childRoomIds)}); }}><MdMode /></Button>
                                    <Button variant='light' onClick={()=>{setShowDeleteModal(true); setSelectedRoom({roomId: room.id, roomName: room.name});}} disabled={!(isAdmin() || isSuperAdmin())}><MdDelete /></Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
        {showAddModal ? <AddRoomModal {...selectedRoom} onCancel={()=>setShowAddModal(false)} onSave={()=>fetchRooms()} /> : <span></span>}
        {showDeleteModal ? <ConfirmationModal title={`Are you sure you want to delete <em>${selectedRoom.roomName}</em> ?`} body="This is irreversible." onCancel={()=>setShowDeleteModal(false)} onConfirm={()=>deleteRoom(selectedRoom.roomId)} /> : <span></span>}
        <ToastContainer />
      </div>
    )
  }