import React, { Fragment, useEffect, useState } from "react";
import Nav from "../components/Nav.js";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import config from "../config.js";
import dayjs from 'dayjs';
import { genericErrorMsg, getRegistrationFormTypes } from "../utils.js";
import Table from 'react-bootstrap/Table';
import ConfirmationModal from "../components/ConfirmationModal.js";
import AddPaymentModal from "../components/AddPaymentModal.js";
import ChangeRoomBookingDatesModal from "../components/ChangeRoomBookingDatesModal.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {MdMode, MdDelete} from "react-icons/md";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import LoadingSpinner from "../components/LoadingSpinner.js";
import AddOfflandDatesModal from "../components/AddOfflandDatesModal.js";
import ChangeBillingAddressModal from "../components/ChangeBillingAddressModal.js";
import ChangeRoomBookingModal from "../components/ChangeRoomBookingModal.js";
import ChangeBookingModal from "../components/ChangeBookingModal.js";
import ChangeZohoIDModal from "../components/ChangeZohoIDModal.js";

export default function BookingDetailsPage() {

  let { bookingId } = useParams();

  let navigate = useNavigate();

  const [booking, setBooking] = useState(null);
  const [showChargeCardModal, setShowChargeCardModal] = useState(false);
  const [showChargeCardSpinner, setShowChargeCardSpinner] = useState(false);
  const [showAddOfflandDatesModal, setShowAddOfflandDatesModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeleteRBModal, setShowDeleteRBModal] = useState(false);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showAddRBModal, setShowAddRBModal] = useState(false);
  const [showChangeZohoIDModal, setShowChangeZohoIDModal] = useState(false);
  const [showChangeBillingAddressModal, setShowChangeBillingAddressModal] = useState(false);
  const [showDeleteOfflandDatesModal, setShowDeleteOfflandDatesModal] = useState(false);
  const [showChangeRoomBookingModal, setShowChangeRoomBookingModal] = useState(false);
  const [selectedRoomBooking, setSelectedRoomBooking] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [selectedOfflandDates, setSelectedOfflandDates] = useState(null);
  const [showSendingEmailSpinner, setShowSendingEmailSpinner] = useState(false);
  const [showDonateConfirmationModal, setShowDonateConfirmationModal] = useState(false);
  const [showDonateBalanceSpinner, setShowDonateBalanceSpinner] = useState(false);
  const [showChangeBookingModal, setShowChangeBookingModal] = useState(false);

  const paymentSchedules = [
    {value: 'Monthly', label: 'Monthly'},
    {value: 'FullPaymentAtBooking', label: 'Full payment when booking'},
    {value: 'FirstMonthPaymentAtBooking', label: 'First month payment when booking'},
    {value: 'PayInSahaja', label: 'Pay in Sahaja'},
  ];
  const registrationFormTypes = getRegistrationFormTypes().map(t=> { return {value: t, label: t}});

  const [paymentMethod, setPaymentMethod] = useState(null);

  const fetchBooking = () => {
    return fetch(`${config.server_base_url}/api/bookings/${bookingId}`)
        .then((response) => response.json())
        .then((data) => {
          setBooking(data.booking);
          if (data.booking.stripePaymentMethodIdKnown) fetchPaymentMethodDetails();
        });
  }

  const deleteBooking = () => {
    fetch(`${config.server_base_url}/api/bookings/${bookingId}`, 
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) navigate(`/calendar`, { replace: false }) });
  }

  const deleteRoomBooking = (rbId) => {
    fetch(`${config.server_base_url}/api/roombookings/${rbId}`, 
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) fetchBooking()});
  }

  const fetchPaymentMethodDetails = () => {
    fetch(`${config.server_base_url}/api/bookings/${bookingId}/paymentMethod`, 
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => setPaymentMethod(data.paymentMethod))
  }

  const takePayment = () => {
    setShowChargeCardSpinner(true);
    fetch(`${config.server_base_url}/api/bookings/${bookingId}/takePayment`, 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            amount: booking.nextPayment.amount
          })
        })
        .then((response) => response.json())
        .then((data) => { fetchBooking() })
        .then(()=>setShowChargeCardSpinner(false))
        .catch((err)=>setShowChargeCardSpinner(false));
  }

  const generateInvoice = (e,paymentId) => {
    e.target.closest('td').querySelector('span').style.display='inline-block';
    e.target.closest('td').querySelector('.btn-link').disabled = true;

    fetch(`${config.server_base_url}/api/bookings/${bookingId}/payments/${paymentId}/generateInvoice`, 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => response.json())
        .then((data) => fetchBooking())
        .catch((error) => {e.target.closest('td').querySelector('span').style.display='none'});
  }  

  const sendBookingConfirmationEmail = (myself = false) => {
    setShowSendingEmailSpinner(true);
    fetch(`${config.server_base_url}/api/bookings/${bookingId}/sendBookingConfirmationEmail`, 
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({myself: myself})
    })
    .then((response) => response.json())
    .then((data) => { setShowSendingEmailSpinner(false); 
                      if (data.success) toast.info('Booking confirmation email sent', {theme: 'colored'})
                      else toast.error( "We couldn't send the booking confirmation email", {theme: 'colored'}) })
    .catch(error => {
      setShowSendingEmailSpinner(false);
      toast.error( genericErrorMsg, {theme: 'colored'})
    });
  }

  const markBalanceAsDonation = (amount) => {
    setShowDonateBalanceSpinner(true);
    fetch(`${config.server_base_url}/api/bookings/${bookingId}/markBalanceAsDonation`, 
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        amount: amount
      })
    })
    .then((response) => response.json())
    .then((data) => { setShowDonateBalanceSpinner(false); fetchBooking(); })
    .catch(error => {
      setShowDonateBalanceSpinner(false);
      toast.error( genericErrorMsg, {theme: 'colored'})
    });
  }

  const deleteOfflandDates = (offlandDatesId) => {
    fetch(`${config.server_base_url}/api/my-booking/${bookingId}/offlandDates/${offlandDatesId}`, 
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) fetchBooking() })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }

  const cancelBooking = () => {
    fetch(`${config.server_base_url}/api/bookings/${bookingId}/cancel`, 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) fetchBooking() });
  }

  useEffect(() => {
    fetchBooking();
  },[])  

  return (
    <div id="app-container" className="app-container">
        <Nav url="/calendar"></Nav>
        <div className='page-header'><h1>Booking #{bookingId}</h1></div>
        <div id="page-container" className="page-container booking-details-page">
          {booking ? 
            <Fragment>
              <h3>{`(${booking.guest.preferredName}) ${booking.guest.firstName} ${booking.guest.lastName}`} ({booking.guest.zohoId ? <a href={`https://crm.zoho.eu/crm/org20063312840/tab/CustomModule12/${booking.guest.zohoId}`} target="_blank">open Zoho</a> : <Fragment>no Zoho ID <Button style={{marginTop: '-4px'}} variant='link' onClick={()=>setShowChangeZohoIDModal(true)}><MdMode /></Button></Fragment> })</h3>
              <Table striped bordered hover className="room-bookings-table">
                <thead>
                  <tr>
                    <td>Room</td>
                    <td>Rate</td>
                    <td>Start Date</td>
                    <td>End Date</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {booking.roomBookings.map((roomBooking,index) =>
                    <tr key={roomBooking.id}>
                      <td className="room-name">{roomBooking.room!=null ? <Fragment>{roomBooking.room.name}{roomBooking.isQuarantineRoom ? <Fragment><br/><em>Quarantine room</em> </Fragment> : ''}</Fragment> : (<Fragment>Not chosen yet. <br/>  <em>{`${roomBooking.blockedRoom.name} is blocked for them.`}</em></Fragment>)}
                      <Button variant='light' style={{marginLeft: '5px'}} onClick={()=> {setSelectedRoomBooking(roomBooking); setShowChangeRoomBookingModal(true); }}><MdMode /></Button> 
                      </td>
                      <td>{roomBooking.rate!=null ? `${roomBooking.rateGroupName} / €${roomBooking.rate.price}` : roomBooking.rateGroupName}
                      <Button variant='light' style={{marginLeft: '5px'}} onClick={()=> {setSelectedRoomBooking(roomBooking); setShowChangeRoomBookingModal(true); }}><MdMode /></Button> 
                      </td>
                      <td>{dayjs(roomBooking.startDate).format('DD MMM YYYY')}</td>
                      <td>{dayjs(roomBooking.endDate).format('DD MMM YYYY')}</td>
                      <td className='row-actions'>
                        <Button variant='light' onClick={()=> {setSelectedRoomBooking(roomBooking); setShowAddRBModal(true); }}><MdMode /></Button> 
                        {index>0 && booking.roomBookings.filter(rb=>!rb.isQuarantineRoom).length>1 && index==booking.roomBookings.length-1 ? <Button variant='light' onClick={()=>{setShowDeleteRBModal(true); setSelectedRoomBooking(roomBooking); }}><MdDelete /></Button> : <span></span>}
                      </td>
                    </tr>
                  )}                  
                </tbody>
              </Table>
              <p><label>Booking status:</label> <em>{booking.status}</em>{booking.status=='confirmed' && booking.guest.email ? <Fragment><Button variant='link' style={{marginTop: -4}} onClick={()=>sendBookingConfirmationEmail(false)}>Send confirmation email to guest</Button> | <Button variant='link' style={{marginTop: -4}} onClick={()=>sendBookingConfirmationEmail(true)}>Send confirmation email to myself {showSendingEmailSpinner ? <LoadingSpinner /> : <Fragment />}</Button></Fragment> : <span></span>}</p>
              <p><label>Booking type:</label> <em>{booking.type}</em></p>
              <p><label>Payment schedule:</label> <em>{paymentSchedules.find(ps=>ps.value==booking.paymentSchedule)?.label}</em> {booking.type=='Guest' ? <Button variant='light' onClick={()=> setShowChangeBookingModal(true)}><MdMode /></Button> : ""} </p>
              {booking.type=='Guest' || booking.type=='Village' ?
                <Fragment>
                  <p><label>Guest can choose room type:</label> <em>{booking.guestCanChooseRoomType ? 'yes' : 'no'}</em></p>
                  <p><label>Registration form:</label> <em>{booking.registrationFormType}</em></p>
                  <p><label>Registration complete:</label> <em>{booking.registrationComplete ? 'yes' : 'no'}</em></p>
                </Fragment> : <span></span>}
              <Tabs className="mb-3">
                <Tab eventKey="payments" title="Payments">
                  {booking.status=='confirmed' ? 
                    <Fragment>
                      {dayjs(booking.paidUntil).isSame(dayjs(booking.roomBookings[0].startDate), 'day') ?
                        <h4>No payments have been done for this booking. {booking.startingBalance>0 ? `Starting balance: €${booking.startingBalance}.`:""}</h4> :
                        <h4>This booking is paid until {dayjs(booking.paidUntil).format('DD MMM YYYY')}{booking.balance>=0 ? ' (fully paid)' : ''}. {booking.startingBalance>0 ? `Starting balance: €${booking.startingBalance}.`:""} {booking.donatedBalance>0 ? `Donated balance: €${booking.donatedBalance}.`:""}</h4>
                      }
                      {booking.nextPayment ? <h4>Next payment: €{booking.nextPayment.amount} for {dayjs(booking.nextPayment.startDate).format('DD MMM YYYY')} - {dayjs(booking.nextPayment.endDate).format('DD MMM YYYY')}. {booking.stripePaymentMethodIdKnown ? <a href='#' onClick={(e)=>{e.preventDefault(); setShowChargeCardModal(true)}}>Charge card now {showChargeCardSpinner ? <LoadingSpinner /> : <Fragment />}</a> : <span></span>} </h4> : <h4>No due payments. {booking.balance>0 ? <Fragment>We owe them €{booking.balance}. <a href='#' onClick={(e)=>{e.preventDefault(); setShowDonateConfirmationModal(true)}}>Mark as donation {showDonateBalanceSpinner ? <LoadingSpinner /> : <Fragment />}</a></Fragment> : ''}</h4>}
                    </Fragment>  
                    :
                    (booking.status!='cancelled' ? <h4>This booking is blocked but has not been confirmed yet.</h4> : <h4>This booking is cancelled.</h4>)
                  }                  
                  {booking.payments && booking.payments.length>0 ? 
                    <Table striped bordered hover className="payments-table">
                      <thead>
                        <tr>
                          <td>Type</td>
                          <td>Amount</td>
                          <td>Date</td>
                          <td className='payment-status'>Status</td>
                          <td>Invoice</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {booking.payments.filter(p=>p.parentPaymentId==null).map((payment) => 
                          <Fragment key={payment.id}>
                            <tr>
                              <td>Payment ({payment.type})</td>
                              <td>€{payment.amount}</td>
                              <td>{dayjs(payment.date).format('DD MMM, YYYY')}</td>
                              <td>{payment.status} {payment.status!='succeeded' ? <span className='card-payment-failed-msg'><br/>{payment.lastPaymentErrorMsg}</span> : ''}</td>
                              <td>{payment.invoiceId ? <Button variant='link' href={payment.invoiceUrl} target='_blank'>Invoice</Button> : (payment.status=='succeeded' && booking.billingAddress ? <Button variant='link' onClick={(e)=>generateInvoice(e, payment.id)}>Generate <span style={{display: 'none'}}><LoadingSpinner/></span></Button> : '')}</td>
                              <td>{payment.status=='succeeded' ? <Button variant='link' onClick={()=> {setSelectedPayment(payment); setShowAddPaymentModal(true);} }>Refund</Button> : <Fragment/>}</td>
                            </tr>
                            {payment.refunds && payment.refunds.map(refund=>
                              <tr key={refund.id} className='refund-row'>
                                <td>Refund ({refund.type})</td>
                                <td>€{refund.amount}</td>
                                <td>{dayjs(refund.date).format('DD MMM, YYYY')}</td>
                                <td>{refund.status} {refund.status!='succeeded' ? <span className='card-payment-failed-msg'><br/>{refund.lastPaymentErrorMsg}</span> : ''}</td>
                                <td>{refund.invoiceId ? <Button variant='link' href={refund.invoiceUrl} target='_blank'>Credit Note</Button> : (refund.status=='succeeded' && booking.billingAddress ? <Button variant='link' onClick={(e)=>generateInvoice(e, refund.id)}>Generate <span style={{display: 'none'}}><LoadingSpinner/></span></Button> : '')}</td>
                                <td></td>
                              </tr>
                              )}
                          </Fragment>)}
                      </tbody>
                    </Table> : <p><i>No payments for this booking.{booking.status=='blocked' ? ' A booking needs to be confirmed before payments can be added to it.': ''}</i></p>}

                  <div className='action-buttons'>
                    {booking.status=='confirmed' ?
                    <Button variant="primary" onClick={()=> {setSelectedPayment(null); setShowAddPaymentModal(true)} } className='mb-2'>Add Payment</Button> : <Fragment />}
                  </div>
                </Tab>
                <Tab eventKey="billing-details" title="Billing Details" className='billing-details-tab'>
                  <p><label>Card on file:</label> <em>{booking.stripePaymentMethodIdKnown && paymentMethod ? <span>{paymentMethod.cardBrand?.toUpperCase()} {paymentMethod.cardLast4}, exp: {paymentMethod.cardExpiryMonth}/{paymentMethod.cardExpiryYear}</span> : 'No'}</em></p>
                  <p><label>Email:</label> <em>{booking.guest.email ?? 'unknown'}</em></p>
                  <p className='billing-address'><label>Billing address:</label> {booking.billingAddress ? <span><em>{booking.billingAddress.streetAddress1} {booking.billingAddress.streetAddress2}<br/>{booking.billingAddress.city}<br/>{booking.billingAddress.postCode}, {booking.billingAddress.state}<br/> {booking.billingAddress.country}</em></span> : <em>unknown. Invoices will not be issued.</em>} <Button variant='light' onClick={()=> setShowChangeBillingAddressModal(true)}><MdMode /></Button></p>
                  <p><label>Tax number:</label> {booking.billingAddress?.taxNumber ? <em>{booking.billingAddress.taxNumber}</em> : <em>unknown</em>}</p>
                </Tab>
                {booking.type=='Team' && booking.offlandDates ?
                  <Tab eventKey="offland-dates" title="Offland Dates">
                    {booking.offlandDates.length ? 
                      <Table striped bordered hover className="offland-dates-table">
                        <thead>
                          <tr>
                            <td>Departure Date</td>
                            <td>Arrival Date</td>
                            <td>Number of Days</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {booking.offlandDates.slice(0,10).map((od, index)=> {
                            const lastPayment = booking.payments.length>0 ? booking.payments[0] : null;
                            const canDelete = booking.nextPayment==null || booking.nextPayment.startDate==null || dayjs(booking.nextPayment.startDate).isBefore(dayjs(od.startDate)) || lastPayment==null || dayjs(lastPayment.date).isBefore(dayjs(od.startDate));
                            const canEdit = booking.nextPayment==null || booking.nextPayment.startDate==null || dayjs(booking.nextPayment.startDate).isBefore(dayjs(od.endDate)) || lastPayment==null || dayjs(lastPayment.date).isBefore(dayjs(od.startDate));

                            return <tr key={index}>
                              <td>{dayjs(od.startDate).format('DD MMM YYYY')}</td>
                              <td>{dayjs(od.endDate).format('DD MMM YYYY')}</td>
                              <td>{dayjs(od.endDate).startOf('day').diff(dayjs(od.startDate).startOf('day'), 'day')}</td>
                              <td className='row-actions'>{canEdit ? <Button variant='light' onClick={()=> {setShowAddOfflandDatesModal(true); setSelectedOfflandDates(od); }}><MdMode /></Button> : <Fragment />} {canDelete ? <Button variant='light' onClick={()=>{setShowDeleteOfflandDatesModal(true); setSelectedOfflandDates(od);}}><MdDelete /></Button> : <Fragment />}</td>
                            </tr>
                          })}
                        </tbody>
                      </Table> : <h4>No offland dates submitted yet.</h4>}
                      <div className='action-buttons'>
                        <Button variant="primary" onClick={()=>{setSelectedOfflandDates(null); setShowAddOfflandDatesModal(true); }}>Add Offland Dates</Button>
                      </div>
                  </Tab> : <Fragment /> }
                  <Tab eventKey="booking-links" title="Links">
                      {booking.type=='Team' ? <p><a href={`/my-booking/${booking.id}/offland`} target='_blank'>Offland dates form</a></p> : <Fragment />}
                      <p style={{marginBottom: 50}}><a href={`/my-booking/${booking.id}`} target='_blank'>{booking.guest.preferredName}'s <em>Booking & Registration</em> link</a></p>
                      {booking.payments.find(p=>p.status=='succeeded') ?
                        booking.status!='cancelled' ? <p><Button variant="danger" onClick={()=> setShowCancelModal(true)} className='mb-2'>Cancel Booking</Button></p> : <span></span> :
                      <p><Button variant="danger" onClick={()=> setShowDeleteModal(true)} className='mb-2'>Delete Booking</Button></p>}
                  </Tab>
              </Tabs>
              {showChargeCardModal ? <ConfirmationModal title="Are you sure you want to charge this card ?" body={`We will charge an amount of €${booking.nextPayment.amount}.`} onCancel={()=>setShowChargeCardModal(false)} onConfirm={takePayment} /> : <span></span>}
              {showDeleteModal ? <ConfirmationModal title="Are you sure you want to delete this booking ?" body="This is irreversible." onCancel={()=>setShowDeleteModal(false)} onConfirm={deleteBooking} /> : <span></span>}
              {showDeleteRBModal ? <ConfirmationModal title="Are you sure you want to delete this room booking ?" body="This is irreversible." onCancel={()=>setShowDeleteModal(false)} onConfirm={()=>deleteRoomBooking(selectedRoomBooking.id)} /> : <span></span>}
              {showAddPaymentModal ? <AddPaymentModal bookingId={bookingId} stripePaymentMethodIdKnown={booking.stripePaymentMethodIdKnown} refund={selectedPayment!=null} parentPayment={selectedPayment} onCancel={()=>setShowAddPaymentModal(false)} onSave={()=>fetchBooking()} /> : <span></span>}
              {showAddRBModal ? <ChangeRoomBookingDatesModal roomBooking={selectedRoomBooking} onCancel={()=>setShowAddRBModal(false)} onSave={()=>fetchBooking()} /> : <span></span>}
              {showAddOfflandDatesModal ? <AddOfflandDatesModal booking={booking} bookingId={bookingId} {...selectedOfflandDates} hideGuidance={true} onCancel={()=>setShowAddOfflandDatesModal(false)} onSave={()=>fetchBooking()} /> : <span></span>}
              {showDeleteOfflandDatesModal ? <ConfirmationModal title={`Are you sure you want to delete these offland dates ?`} body="This is irreversible." onCancel={()=>setShowDeleteOfflandDatesModal(false)} onConfirm={()=>deleteOfflandDates(selectedOfflandDates.id)} /> : <span></span>}
              {showChangeBillingAddressModal ? <ChangeBillingAddressModal booking={booking} onCancel={()=>setShowChangeBillingAddressModal(false)} onSave={()=>fetchBooking()} /> : <span></span>}
              {showChangeRoomBookingModal ? <ChangeRoomBookingModal guestName={`${booking.guest.preferredName} ${booking.guest.lastName.slice(0,3)}. (${dayjs(selectedRoomBooking.startDate).format('DD MMM')}-${dayjs(selectedRoomBooking.endDate).format('DD MMM')})`} startDate={selectedRoomBooking.startDate} endDate={selectedRoomBooking.endDate} roomBookingId={selectedRoomBooking.id} roomId={selectedRoomBooking.roomId ?? selectedRoomBooking.blockedRoomId} roomRateId={selectedRoomBooking.rateId} roomTypeId={selectedRoomBooking.room?.roomTypeId ?? selectedRoomBooking.blockedRoom?.roomTypeId} roomRateGroup={selectedRoomBooking.rateGroupName} isQuarantineRoom={selectedRoomBooking.isQuarantineRoom}  isBlockedRoom={selectedRoomBooking.blockedRoomId!=null} onCancel={()=>setShowChangeRoomBookingModal(false)} onSave={()=>fetchBooking()}/> : <span></span>}
              {showChangeBookingModal ? <ChangeBookingModal booking={booking} paymentSchedules={paymentSchedules} registrationFormTypes={registrationFormTypes} onCancel={()=>setShowChangeBookingModal(false)} onSave={()=>fetchBooking()} /> : <span></span>}
              {showChangeZohoIDModal ? <ChangeZohoIDModal booking={booking} onCancel={()=>setShowChangeZohoIDModal(false)} onSave={()=>fetchBooking()} /> : <span></span> }
              {showCancelModal ? <ConfirmationModal title="Are you sure you want to cancel this booking ?" body="Rooms will become available but you will still need to issue any refunds manually. This is irreversible." onCancel={()=>setShowCancelModal(false)} onConfirm={cancelBooking} /> : <span></span>}
              {showDonateConfirmationModal ? <ConfirmationModal title={`Are you sure you want to mark €${booking.balance} as donation ?`} body="The balance for this booking will be settled." onCancel={()=>setShowDonateConfirmationModal(false)} onConfirm={()=>markBalanceAsDonation(booking.balance)} /> : <span></span>}
            </Fragment>
            : <div></div>}
        </div>
        <ToastContainer />
    </div>
  )
}