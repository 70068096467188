import dayjs from 'dayjs';
import { Fragment, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import config from "../config.js";
import DatePicker from "react-datepicker";
import LoadingSpinner from './LoadingSpinner.js';

export default function ChangeRoomBookingModal(props) {
    
    const [errorMsg, setErrorMsg] = useState(null);

    const [rooms, setRooms] = useState(null);
    const [rates, setRates] = useState([]);
    const [rateGroups, setRateGroups] = useState([]);

    const [fullRateGroups, setFullRateGroups] = useState([]);

    const [changeFromDate, setChangeFromDate] = useState(dayjs(props.clickedDate ?? props.startDate).toDate());
    const [changeToDate, setChangeToDate] = useState(dayjs(props.endDate).toDate());
    const [selectedRoom, setSelectedRoom] = useState(props.roomId);
    const [selectedRate, setSelectedRate] = useState(props.roomRateId);
    const [selectedRateGroup, setSelectedRateGroup] = useState(props.roomRateGroup);

    const [saving, setSaving] = useState(false);
    const [fetchingRooms, setFetchingRooms] = useState(false);

    const save = () => {
        if (!selectedRoom) {
            setErrorMsg(`No rate for this room in ${selectedRateGroup}`);
            return;
        }
        if (props.roomRateId && !selectedRate) {
            setErrorMsg(`Please choose a rate`);
            return;
        }
        setSaving(true);

        fetch(`${config.server_base_url}/api/roombookings/${props.roomBookingId}/changeRoomOrRate`, 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            changeFromDate: dayjs(changeFromDate).format('YYYY-MM-DD'),
            changeToDate: dayjs(changeToDate).format('YYYY-MM-DD'),
            roomId: selectedRoom,
            rateId: selectedRate,
            rateGroupName: selectedRateGroup
          })
        })
        .then((response) => response.json())
        .then((data) => { setSaving(false); if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) });
    }

    const fetchRooms = () => {
        setFetchingRooms(true);
        fetch(`${config.server_base_url}/api/rooms?` + new URLSearchParams({
              filterNotBookedStartDate: dayjs(changeFromDate).format('YYYY-MM-DD'),
              filterNotBookedEndDate: dayjs(changeToDate).format('YYYY-MM-DD'),
              includeRoomBookingId: props.roomBookingId,
              filterRateGroup: selectedRateGroup,
              includeOfflandRooms: true,
            }))
            .then((response) => response.json())
            .then((data) => {
                setFetchingRooms(false);
                if (!data.rooms.find(r=>r.id==selectedRoom)) setSelectedRoom(null);
                setRooms(data.rooms.map(r=> { return { value: r.id, label: r.isOffland ? `${r.name} - offland` : r.name}}))
            })
    } 

    const fetchRateGroups = () => {
        fetch(`${config.server_base_url}/api/rateGroups`)
            .then((response) => response.json())
            .then((data) => {
                setFullRateGroups(data.rateGroups);
                setRateGroups(data.rateGroups.map(rg=> { return { value: rg.name, label: rg.name}}));
                setRates(data.rateGroups
                    .flatMap(rg=>rg.roomTypes)
                    .filter(rt=>rt.rate!=null)
                    .map(rtr=>rtr.rate)
                    .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)) //deduping
                    .sort((a,b)=>a.price - b.price)
                    .map(rt=> { return { value: rt.id, label: `€${rt.price}`}}));
            });
    }  

    const filterRooms = (roomOp, text) => {
        if (text.length==0) return rooms;
        const words = text.split(' ');
        return !(words.map(w=>roomOp.label.toLowerCase().includes(w.toLowerCase())).includes(false));
    }; 

    useEffect(() => {
        fetchRateGroups(); 
    },[]) 

    useEffect(() => {
        fetchRooms(); 
    },[changeFromDate, changeToDate, selectedRateGroup])     

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel}>
                {(rates && rates.length>0 && rooms) ? 
                    <Fragment>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Room/Rate</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='change-room-booking-form'>
                                <p>Use this form to change the room or rate for a certain period within the current booking dates.</p>
                                {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                                <p>Change for <em>{props.guestName}</em></p>
                                <div className='form-row dates-row'>
                                    <div>
                                        <label>From Date:</label>
                                        <DatePicker selected={changeFromDate} minDate={dayjs(props.startDate).toDate()} maxDate={dayjs(props.endDate).toDate()} onChange={(date) => setChangeFromDate(date)} disabled={props.isQuarantineRoom || props.isBlockedRoom} dateFormat="dd MMM, yyyy" calendarStartDay={1} onKeyDown={(e) => { e.preventDefault();}} />
                                    </div>
                                    <div>
                                        <label>To Date:</label>
                                        <DatePicker selected={changeToDate} minDate={dayjs(changeFromDate).add(1, 'day').toDate()} maxDate={dayjs(props.endDate).toDate()} onChange={(date) => setChangeToDate(date)} disabled={props.isQuarantineRoom || props.isBlockedRoom} dateFormat="dd MMM, yyyy" calendarStartDay={1} onKeyDown={(e) => { e.preventDefault();}} />
                                    </div>
                                </div>
                                <div className='form-row'>
                                    <label>Room:{fetchingRooms ? <LoadingSpinner />:<Fragment/>}</label>
                                    <Select key={`${changeFromDate}-${changeToDate}-${selectedRate}-${rooms.length}`} defaultValue={()=>rooms.find(r=>r.value==selectedRoom)} options={rooms} onChange={(room)=>setSelectedRoom(room.value)} filterOption={(candidate, input)=>filterRooms(candidate, input)}/>
                                </div>
                                <div className='form-row rate-group-row'>
                                    <label>Rate Group:</label>
                                    <Select options={rateGroups} defaultValue={()=>rateGroups.find(rg=>rg.value==props.roomRateGroup)} onChange={(rg)=>{
                                        setSelectedRateGroup(rg.value);
                                        if (props.roomTypeId && selectedRate) {
                                            setSelectedRate(fullRateGroups.find(gr=>gr.name==rg.value).roomTypes.find(rt=>rt.id==props.roomTypeId)?.rate?.id);
                                        }
                                    }}/>
                                </div>  
                                {props.roomRateId ?                               
                                <div className='form-row rate-row'>
                                    <label>Rate:</label>
                                    <Select key={selectedRateGroup} options={rates} defaultValue={()=>rates.find(rt=>rt.value==selectedRate)} onChange={(rate)=>setSelectedRate(rate.value)}/>
                                </div> : <Fragment />}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                            <Button variant="primary" onClick={save} disabled={saving}>Save {saving ? <LoadingSpinner />:''}</Button>
                        </Modal.Footer>
                    </Fragment> :<span></span>}
            </Modal>
        </Fragment>
    );
}