import React, { Fragment, useEffect, useState } from "react";

import Nav from "../components/Nav.js";
import config from "../config.js";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {IoAddOutline} from "react-icons/io5";
import AddRateGroupModal from "../components/AddRateGroupModal.js";
import ConfirmationModal from "../components/ConfirmationModal.js";
import {MdMode, MdDelete, MdContentCopy} from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { genericErrorMsg, isAdmin, isSuperAdmin } from "../utils.js";

export default function RatesPage() {

    const [rates, setRates] = useState([]);
    const [roomTypes, setRoomTypes] = useState([]);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [isCloning, setIsCloning] = useState(false);
    const [selectedRateGroup, setSelectedRateGroup] = useState(null);

    const fetchRates = () => {
        return fetch(`${config.server_base_url}/api/rateGroups`)
            .then((response) => response.json())
            .then((data) => setRates(data.rateGroups));
    }

    const fetchRoomTypes = () => {
        return fetch(`${config.server_base_url}/api/roomTypesFull`)
            .then((response) => response.json())
            .then((data) => setRoomTypes(data.roomTypes));
    }

    const deleteRateGroup = (rateGroupName) => {
        fetch(`${config.server_base_url}/api/rateGroups/${rateGroupName}`, 
            {
              method: 'DELETE',
              headers: { 'Content-Type': 'application/json' },
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) fetchRates(); else toast.error( data.reason, {theme: 'colored'}) })
            .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
      }    

    useEffect(() => {
        fetchRates();
        fetchRoomTypes();
    },[])

    return (
      <div id="app-container" className="app-container">
        <Nav url="/rates"></Nav>
        <div className='page-header'><h1>Rates</h1></div>
        <div id="page-container" className="page-container rates-page">
            <div className='data-table-container'>
                <div className='table-actions'>
                    <Button variant="primary" onClick={()=>{setSelectedRateGroup(null); setIsCloning(false); setShowAddModal(true); }} disabled={!(isAdmin() || isSuperAdmin())}><IoAddOutline /> Create</Button>
                </div>
                <Table striped bordered hover className='rates-table' cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            {rates && rates.length > 0 && rates[0].roomTypes.map((roomType) => (
                                <th key={roomType.id}>{roomType.name}</th>
                            ))}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>                    
                        {rates && rates.length > 0 && rates.map((rate) => (
                            <tr key={rate.name}>
                                <td>{rate.name}<br/><span className='rate-description'>{rate.description}</span></td>
                                {rate.roomTypes.map((roomType) => {
                                    if (roomType.rate != null) {
                                        return <Fragment key={roomType.id}>
                                            <td>
                                                <span className={`total-price${roomType.isBookable ? ' bookable' : ''}`}>€{roomType.rate.price}</span>
                                                <div className='price-breakdown'>
                                                    Room: €{roomType.rate.roomPrice.toFixed(2)}<br/>
                                                    Meals: €{roomType.rate.mealsPrice.toFixed(2)}
                                                </div>
                                            </td>
                                        </Fragment>
                                    } return <Fragment key={roomType.id}>
                                            <td>&nbsp;</td>
                                        </Fragment>
                                })}
                                <td className='row-actions'>
                                    <Button title="Clone" variant='light' onClick={()=> {setShowAddModal(true); setIsCloning(true); setSelectedRateGroup(rate); }}><MdContentCopy /></Button> 
                                    <Button variant='light' onClick={()=> {setShowAddModal(true); setIsCloning(false); setSelectedRateGroup(rate); }}><MdMode /></Button> 
                                    <Button variant='light' onClick={()=>{setShowDeleteModal(true); setSelectedRateGroup(rate);}} disabled={!(isAdmin() || isSuperAdmin())}><MdDelete /></Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
        {showAddModal ? <AddRateGroupModal roomTypes={roomTypes} rateGroup={selectedRateGroup} isCloning={isCloning} onCancel={()=>setShowAddModal(false)} onSave={()=>fetchRates()} /> : <span></span>}
        {showDeleteModal ? <ConfirmationModal title={`Are you sure you want to delete <em>${selectedRateGroup.name}</em> ?`} body="This is irreversible." onCancel={()=>setShowDeleteModal(false)} onConfirm={()=>deleteRateGroup(selectedRateGroup.name)} /> : <span></span>}
        <ToastContainer />
      </div>
    )
  }