import dayjs from 'dayjs';
import { Fragment, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import config from "../config.js";
import { genericErrorMsg } from "../utils.js";
import Select from 'react-select';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';

export default function AddOfflandDatesModal(props) {
    
    const [errorMsg, setErrorMsg] = useState(null);

    const [startDate, setStartDate] = useState(dayjs(props.startDate).toDate());
    const [endDate, setEndDate] = useState(props.endDate ? dayjs(props.endDate).toDate() : dayjs(props.startDate).add(3, 'day').toDate() );

    const minDate = dayjs(props.booking.nextPayment?.startDate ?? dayjs().startOf('month')).toDate();

    const canChangeStartDate = props.id==null || props.booking.nextPayment==null || props.booking.nextPayment.startDate==null || dayjs(props.booking.nextPayment.startDate).isBefore(dayjs(props.startDate));

    const [saving, setSaving] = useState(false);

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.add-offland-dates-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {
            setSaving(true);
            fetch(`${config.server_base_url}/api/my-booking/${props.bookingId}/offlandDates` + (props.id ? `/${props.id}`: ''), 
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                startDate: dayjs(startDate).format('YYYY-MM-DD'), 
                endDate:dayjs(endDate).format('YYYY-MM-DD')
              })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else {setErrorMsg(data.reason); setSaving(false);} })
            .catch((error) => {setSaving(false); toast.error( genericErrorMsg, {theme: 'colored'})});
        }
        return false;
    }

    useEffect(()=> {
    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='add-offland-dates-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{props.id ? 'Update' : 'Add'} Offland Dates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='add-offland-dates-form'>
                        {props.hideGuidance ? <p></p> : <p>Please remember to clean your accommodation & pack your things if you are leaving for more than 7 days, because we may need to allocate it to a short-term guest.</p>}
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div className='form-row dates-row'>
                            <div>
                                <label>Departure:</label>
                                <DatePicker disabled={!canChangeStartDate} selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} minDate={minDate} onKeyDown={(e) => { e.preventDefault();}}/>
                            </div>
                            <div>
                                <label>Arrival:</label>
                                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} minDate={dayjs(startDate).add(3, 'day').toDate()} onKeyDown={(e) => { e.preventDefault();}} />
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" disabled={saving} onClick={save} onDoubleClick={()=>{return false;}}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}