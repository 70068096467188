import React, { useEffect, useState, useMemo, Fragment } from "react";
import {PaymentElement, useStripe, useElements} from "@stripe/react-stripe-js";
import Form from 'react-bootstrap/Form';
import { Country }  from 'country-state-city';
import config from "../config";
import { genericErrorMsg } from "../utils.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import BillingAddressFields from "./BillingAddressFields";
import Button from 'react-bootstrap/Button';
import GuestDetailsFields from "./GuestDetailsFields";
import dayjs from "dayjs";

export default function CheckoutForm(props) {

  const payment = props.payment!=null ? props.payment : true;

  const stripe = payment ? useStripe() : null;
  const elements = payment ? useElements() : null;

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe || !payment) return;

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) return;

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (payment && (!stripe || !elements)) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const paymentForm = document.querySelector('#payment-form');
    paymentForm.classList.add('was-validated');

    if (paymentForm.checkValidity()===true) {
      setIsLoading(true);
      const saveBillingAddressEndpoint = props.isOpenBooking ? 
        `${config.server_base_url}/api/open-booking/createBooking`:
        `${config.server_base_url}/api/my-booking/${props.bookingId}/saveBillingAddress`;
      fetch(saveBillingAddressEndpoint, 
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            confirmWithNoPayment: !payment, 
            property: props.isOpenBooking ? props.property : undefined,
            paymentIntentId: props.isOpenBooking ? props.paymentIntentId : undefined,
            startDate: props.isOpenBooking ? dayjs(document.getElementsByName('startDate')[0].value).format('YYYY-MM-DD') : undefined,
            endDate: props.isOpenBooking ? dayjs(document.getElementsByName('endDate')[0].value).format('YYYY-MM-DD') : undefined,
            rtRateId: props.isOpenBooking ? parseInt(document.querySelector('input[name="room-type-choice"]:checked').value) : undefined,
            firstName: props.isOpenBooking ? document.getElementsByName('firstName')[0].value : undefined,
            lastName: props.isOpenBooking ? document.getElementsByName('lastName')[0].value : undefined,
            email: props.isOpenBooking ? document.getElementsByName('email')[0].value : undefined,
            phone: props.isOpenBooking ? document.getElementsByName('phone')[0].value : undefined,
            secondGuestFirstName: props.isOpenBooking && document.getElementsByName('firstName').length>1 ? document.getElementsByName('firstName')[1].value : undefined,
            secondGuestLastName: props.isOpenBooking && document.getElementsByName('lastName').length>1 ? document.getElementsByName('lastName')[1].value : undefined,
            streetAddress1: props.amount > 0 ? document.getElementsByName('streetAddress1')[0].value : '',
            streetAddress2: props.amount > 0 ? document.getElementsByName('streetAddress2')[0].value : '',
            city: props.amount > 0 ? document.getElementsByName('city')[0].value : '',
            country: props.amount > 0 ? Country.getCountryByCode(document.getElementsByName('country')[0].value).name : '',
            state: props.amount > 0 ? document.getElementsByName('state')[0].value : '',
            postCode: props.amount > 0 ? document.getElementsByName('postCode')[0].value : '',
            taxNumber: props.amount > 0 ? document.getElementsByName('taxNumber')[0].value : ''
          })          
        })
        .then((response) => response.json())
        .then((data) => { setIsLoading(false); if (data.success) { 
              if (payment) takePayment();
              else props.onBillingAddressSaved(data);
            } else {
              toast.error( data.reason, {theme: 'colored'})
              if (props.isOpenBooking && data.sessionExpired && props.onSessionExpired ) props.onSessionExpired();
            }})
        .catch((error) => { setIsLoading(false); toast.error( genericErrorMsg, {theme: 'colored'})} );
    } else {
      const invalidFields = document.getElementsByClassName('invalid');
      if (invalidFields.length) invalidFields[0].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  const takePayment = async ()=> {
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: props.returnUrl,
        payment_method_data: {
          billing_details: {
            address: {
              country: document.getElementsByName('country')[0].value
            }
          }
        }
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      toast.error( error.message, {theme: 'colored'});
    } else {
      setMessage("An unexpected error occurred.");
      toast.error("An unexpected error occurred.", {theme: 'colored'});
    }

    setIsLoading(false);
  }

  const paymentElementOptions = {
    layout: "tabs",
    fields: {
      billingDetails: {
        address: {
          country: 'never'
        }
      }
    }
  }

  return (
    <Form noValidate id="payment-form" onSubmit={handleSubmit}>
      {props.isOpenBooking ?
      <Fragment>
        <h2>Primary Guest</h2>
        <GuestDetailsFields />
        {props.showSecondGuestFields ?
        <Fragment>
          <h3>Second Guest {props.secondGuestFieldsRequired ? '' : '(optional)'}</h3>
          <GuestDetailsFields isSecondGuest secondGuestRequired={props.secondGuestFieldsRequired} />
        </Fragment>: <Fragment />}
      </Fragment>:<span></span>}
      {props.amount >0 ? <Fragment>
      <h2>Billing Address</h2>
      <BillingAddressFields billingAddress={props.billingAddress} bookingType={props.bookingType} />
      </Fragment> : <Fragment />}

      {payment ?
      <Fragment>
        <h2>Payment</h2>
        <PaymentElement className="payment-element" options={paymentElementOptions} />
        <button disabled={isLoading || !stripe || !elements} className="pay-button">
          <span id="button-text">
            {isLoading ? <div className="pay-spinner"></div> : `Pay €${props.amount} now`}
          </span>
        </button>
        {message && <div className="payment-message">{message}</div>}
      </Fragment> : <Button className='submit-booking-button' type='submit'>Submit</Button>}
      <ToastContainer />
    </Form>
  );
}