import React, { useEffect, useState, useMemo, Fragment } from "react";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import { Country, State }  from 'country-state-city';

export default function RegistrationForm(props) {

  const countries = useMemo(() => Country.getAllCountries().map(c=>{ return {value: c.name, label: c.name}}), [])

  const [docExpiryDate, setDocExpiryDate] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState(null); 
  const [visaStartDate, setVisaStartDate] = useState(null); 
  const [visaEndDate, setVisaEndDate] = useState(null); 

  const [idTypeValid, setIdTypeValid] = useState(false);
  const [nationalityValid, setNationalityValid] = useState(false);
  const [residenceValid, setResidenceValid] = useState(false);
  const [passportCountryValid, setPassportCountryValid] = useState(false);
  const [insuranceCountryValid, setInsuranceCountryValid] = useState(false);
  const [ecCountryValid, setEcCountryValid] = useState(false);
  const [arrivalTimeCarValid, setArrivalTimeCarValid] = useState(false);
  const [arrivalTimeTrainValid, setArrivalTimeTrainValid] = useState(false);
  const [departureTimeCarValid, setDepartureTimeCarValid] = useState(false);
  const [departureTimeTrainValid, setDepartureTimeTrainValid] = useState(false);

  const [arrivalWay, setArrivalWay] = useState(null);
  const [departureWay, setDepartureWay] = useState(null);

  const [medicalConditionsYesNo, setMedicalConditionsYesNo] = useState(null);
  const [allergiesYesNo, setAllergiesYesNo] = useState(null);
  const [hospitalisationYesNo, setHospitalisationYesNo] = useState(null);
  const [physicalYesNo, setPhysicalYesNo] = useState(null);
  const [allergiesMedicationYesNo, setAllergiesMedicationYesNo] = useState(null);

  const validateFile = (el)=> {
    if (el.files.length && el.files[0].size>4*1024*1024) {
      el.setCustomValidity('File is too large. Maximum 4MB please.');
      el.classList.add('invalid');
    } else {
      el.setCustomValidity('');
      el.classList.remove('invalid');
    }
  }

  const validatePhone = (el)=> {
    var regexp = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
    var isValid = regexp.test(el.value);

    el.setCustomValidity(isValid ? '' : 'Please enter a valid phone number');
    el.classList.toggle('invalid', !isValid);
  }  

  const validatePolicyNumber = (el) => {
    var regexp = /^[a-zA-Z0-9\s]+$/;
    var isValid = regexp.test(el.value);

    el.setCustomValidity(isValid ? '' : 'Please enter only letters, numbers and spaces');
    el.classList.toggle('invalid', !isValid);
  }

  return (

    <Form noValidate className='registration-form'>
      <h2>Travel Documents</h2>
      {props.isOpenBooking ?
      <Fragment>
      <div>
          <label className='required'>Nationality</label>
          <Select required name="Nationality" className={nationalityValid ? 'valid' : 'invalid'} onChange={()=>setNationalityValid(true)} options={countries} />
      </div>
      <div>
          <label className='required'>Country of Residence</label>
          <Select required name="Country_of_Residence" className={residenceValid ? 'valid' : 'invalid'} onChange={()=>setResidenceValid(true)} options={countries} />
      </div>
      <div>
        <label className='required'>Date of Birth</label>
        <DatePicker name="Date_of_Birth" onKeyDown={(e) => { e.preventDefault();}} selected={birthDate} onChange={(date)=>setBirthDate(date)} className="form-control" dateFormat="dd MMM, yyyy" calendarStartDay={1} required showYearDropdown scrollableYearDropdown yearDropdownItemNumber={100} maxDate={new Date()} />
      </div>
      </Fragment> : <span></span>}
      <div>
          <label className='required'>Type of ID (Passport, EU ID, etc.)</label>
          <Select required name="Type_of_Document" className={idTypeValid ? 'valid' : 'invalid'} onChange={()=>setIdTypeValid(true)} options={['Passport', 'EU ID'].map(v=>{ return {value: v, label: v}})} />
      </div>
      <div>
          <label className='required'>Passport / Document Issued By</label>
          <Select required name="Issued_By" className={passportCountryValid ? 'valid' : 'invalid'} onChange={()=>setPassportCountryValid(true)} options={countries} />
      </div>
      <div>
          <label className='required'>ID or Passport Number</label>
          <Form.Control name="ID_or_Passport_Number" type="text" required />
      </div>
      <div>
        <label className='required'>Document Expiration Date</label>
        <DatePicker name="Document_Expiration_Date" onKeyDown={(e) => { e.preventDefault();}} selected={docExpiryDate} onChange={(date)=>setDocExpiryDate(date)} className="form-control" dateFormat="dd MMM, yyyy" calendarStartDay={1} required showYearDropdown scrollableYearDropdown yearDropdownItemNumber={50} minDate={new Date()} />
      </div>
      <div>
        <label className='required'>Please upload an image of your passport or ID</label>
        <Form.Control name="idPhoto" type="file" accept="image/jpeg" required onChange={(e)=>validateFile(e.target)}/>
        <span className='form-field-info'>Only jpeg and jpg files allowed. Max size 4MB</span>
      </div>
      
      <h2>Visa Information</h2>
      <span className='form-field-info'>If you come from one of the countries that require a Visa for Portugal, please provide the dates of your Visa allowance.</span>

      <div>
        <label>Visa Start Date</label>
        <DatePicker name="Visa_Start_Date" onKeyDown={(e) => { e.preventDefault();}} selected={visaStartDate} onChange={(date)=>setVisaStartDate(date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} />
      </div>
      <div>
        <label>Visa End Date</label>
        <DatePicker name="Visa_End_Date" onKeyDown={(e) => { e.preventDefault();}} selected={visaEndDate} onChange={(date)=>setVisaEndDate(date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} />
      </div>

      {!props.isOpenBooking ?
      <Fragment>
      <h2>Health Insurance Information</h2>
      <span className='form-field-info'>Please list details for the health insurance that will cover you during your stay in Portugal.</span>

      <div>
        <label className='required'>Insurance Company Name</label>
        <Form.Control name="Insurance_Name" type="text" required />
      </div>
      <div>
        <label className='required'>Policy Number</label>
        <Form.Control name="Insurance_Policy_Num" type="text" onChange={(e)=>validatePolicyNumber(e.target)} required />
      </div>    
      <div>
          <label className='required'>Country Where Issued</label>
          <Select required name="Insurance_Country" className={insuranceCountryValid ? 'valid' : 'invalid'} onChange={()=>setInsuranceCountryValid(true)} options={countries} />
      </div>
      <div>
        <label className='required'>Expiration Date</label>
        <DatePicker name="Insurance_Exp_Date" onKeyDown={(e) => { e.preventDefault();}} selected={insuranceExpiryDate} onChange={(date)=>setInsuranceExpiryDate(date)} className="form-control" dateFormat="dd MMM, yyyy" calendarStartDay={1} required />
      </div>
      <div>
        <label className='required'>Insurance Contact Phone</label>
        <Form.Control name="Insurance_Contact_Phone2" type="text" required/>
      </div>             
      <div>
        <label className='required'>Photo of Insurance card</label>
        <Form.Control name="insurancePhoto" type="file" accept="image/jpeg" required />
        <span className='form-field-info'>Only jpeg and jpg files allowed. Max size 4MB</span>
      </div>
      </Fragment> : <span></span>}

      <h2>Emergency Contact</h2>

      <div>
        <label className='required'>Emergency Contact First name</label>
        <Form.Control name="EC_First_Name" type="text" required />
      </div> 
      <div>
        <label className='required'>Emergency Contact Last name</label>
        <Form.Control name="EC_Last_Name" type="text" required />
      </div> 
      <div>
        <label className='required'>Relationship to You</label>
        <Form.Control name="EC_Relationship" type="text" required />
      </div> 
      <div>
        <label className='required'>Emergency Contact Email</label>
        <Form.Control name="EC_Email" type="text" required />
      </div> 
      <div>
        <label className='required'>Emergency Contact Phone</label>
        <Form.Control name="EC_Phone" type="text" onChange={(e)=>validatePhone(e.target)} required />
      </div> 
      <div>
          <label className='required'>Emergency Contact Country</label>
          <Select required name="EC_Country" className={ecCountryValid ? 'valid' : 'invalid'} onChange={()=>setEcCountryValid(true)} options={countries} />
      </div>

      {!props.isOpenBooking ?
      <Fragment>
      <h2>Health Conditions</h2>
      <p>Monte Sahaja is located in a remote region of Portugal. The closest hospital is an hour’s drive away and emergency services can take over an hour to arrive. For these reasons, we ask all coming to Monte Sahaja to fill out the following questions honestly and completely in order to ensure a comfortable and safe stay here. </p>
      <span className='form-field-info' style={{fontSize: '90%', fontStyle: 'italic'}}>The information you give regarding health will be deleted 6 months after leaving Monte Sahaja, according to our Privacy Policy.</span>

      <div>
          <label className='required'>Do you have any current medical conditions<br/> (physical or mental)?</label>
          <Select required name="Medical_conditions_Yes_No" className={medicalConditionsYesNo!=null ? 'valid' : 'invalid'} onChange={(op)=>{setMedicalConditionsYesNo(op.value=='Yes')}} options={['Yes', 'No'].map(v=>{ return {value: v, label: v}})} />
      </div>

      {medicalConditionsYesNo ? 
        <div>
            <label className='required'>If yes, please provide further information.</label>
            <Form.Control name="Med_Conditions_reg" as="textarea" rows={3} required />
        </div> : <span></span> }

      <div>
          <label className='required'>Have any of these conditions ever required hospitalisation?</label>
          <Select required name="Hospitalisation_Yes_No" className={hospitalisationYesNo!=null ? 'valid' : 'invalid'} onChange={(op)=>setHospitalisationYesNo(op.value=='Yes')} options={['Yes', 'No'].map(v=>{ return {value: v, label: v}})} />
      </div>

      {hospitalisationYesNo ? 
        <div>
            <label className='required'>If yes, please provide further information.</label>
            <Form.Control name="Occupation_Experience" as="textarea" rows={3} required />
        </div> : <span></span> }

      <div>
          <label className='required'>Do you have any allergies (food, medication,<br/> environmental etc.)?</label>
          <Select required name="Allergies" className={allergiesYesNo!=null ? 'valid' : 'invalid'} onChange={(op)=>setAllergiesYesNo(op.value=='Yes')} options={['Yes', 'No'].map(v=>{ return {value: v, label: v}})} />
      </div>

      {allergiesYesNo ? 
        <div>
            <label className='required'>If so, do you require medication for them?</label>
            <Select required name="Medication_for_Allergies" className={allergiesMedicationYesNo!=null ? 'valid' : 'invalid'} onChange={(op)=>setAllergiesMedicationYesNo(op.value=='Yes')} options={['Yes', 'No'].map(v=>{ return {value: v, label: v}})} />
        </div> : <span></span> }      

      <p><b>If you take any prescribed medication for regular or emergency use, please make sure you bring enough for your entire stay in Monte Sahaja.​</b></p>

      <div>
          <label className='required'>Do you have any health conditions where the facilities at Monte Sahaja may be challenging (eg the showers and toilets are usually some distance from accommodation, may involve steps)?</label>
          <Select required name="Physical_Conditions" className={physicalYesNo!=null ? 'valid' : 'invalid'} onChange={(op)=>setPhysicalYesNo(op.value=='Yes')} options={['Yes', 'No'].map(v=>{ return {value: v, label: v}})} />
      </div>

      <p>If yes, please be in contact with us (<a href='mailto:sahaja.invitations@mooji.org'>sahaja.invitations@mooji.org</a>) so we can see how we can support you.</p>

      <h2>Luggage Allowance</h2>

      <p>The accommodations in Monte Sahaja are located in beautiful places in nature, usually on hills accessible by narrow footpaths and steps. To help you and the team with luggage movements, we have a luggage limit. You can bring the following: </p>

      <p><b>1 luggage of 23 kg<br/>1 carry-on luggage of 10 kg<br/>1 hand bag/backpack</b></p>

      {props.registrationFormType=='default' ?
      <div>
          <label className='required'>For ARRIVAL: will you take the train to Funcheira, or will you arrive by car?</label>
          <Select required name="Arr_Transport_Type" className={arrivalWay!=null ? 'valid' : 'invalid'} onChange={(w)=>setArrivalWay(w.value)} options={['Train', 'Car'].map(v=>{ return {value: v, label: v}})} />
      </div> : <span></span>}
      {props.registrationFormType=='Guest Stay Summer 2024' ?
      <div>
          <label className='required'>For ARRIVAL: will you take one of the buses from Lisbon or Faro to Monte Sahaja, or will you arrive by car/taxi?</label>
          <Select required name="Arr_Transport_Type" className={arrivalWay!=null ? 'valid' : 'invalid'} onChange={(w)=>setArrivalWay(w.value)} options={['8:30 Bus from LISBON, 27 May', '8:30 Bus from FARO, 27 May', 'Car/taxi arrival between 09:00-10:00, 27 May'].map(v=>{ return {value: v, label: v}})} />
      </div> : <span></span>}
      <div />

      {arrivalWay=='Car' ?
        <Fragment>
          <p>If you arrive by car, please plan your arrival at Monte Sahaja for {props.registrationFormType=='default' ? '2:15pm' : '11:30am'}. We will send you driving and parking instructions closer to your arrival time.</p>
          {props.registrationFormType=='default' ?
          <div>
            <label className='required'>Arrival Time by Car</label>
            <Select required name="Car_Arrival_Time" className={arrivalTimeCarValid ? 'valid' : 'invalid'} onChange={()=>setArrivalTimeCarValid(true)} options={['12:20', '16:20'].map(v=>{ return {value: v, label: v}})} />
          </div> : <div></div>}
          <div />
        </Fragment> : <span></span>}

      {arrivalWay=='Train' ?
        <Fragment>
          <p>Please select your train times below. You will have the opportunity to update them later, but please do not change your plans without notifying us. <br/>From Funcheira station you may take a taxi to Monte Sahaja.</p>
          <div>
            <label className='required'>Arrival Time at Funcheira Station</label>
            <Select required name="Arrival_Time" className={arrivalTimeTrainValid ? 'valid' : 'invalid'} onChange={()=>setArrivalTimeTrainValid(true)} options={['11:56 From Lisbon', '15:48 From Faro', '15:53 From Lisbon'].map(v=>{ return {value: v, label: v}})} />
          </div>
        <div />
        </Fragment> : <span></span>}   


      {arrivalWay=='Bus' && props.registrationFormType=='Lisbon 2024' ?
        <Fragment>
          <div>
            <p><strong>Meeting Point: Aula Magna main entrance.</strong><br/>
            <strong>Time: 8:30am on Monday, 8 April.</strong></p>
            <p>The cost for the bus is <em>€20</em>.</p>
          </div>
          <div />
        </Fragment> : <span></span>}                  

      {props.registrationFormType=='default' ?
      <Fragment>
        <div>
          <label className='required'>For DEPARTURE: will you take the train from Funcheira, or will you depart by car?</label>
          <Select required name="Dep_Transport_Type" className={departureWay!=null ? 'valid' : 'invalid'} onChange={(w)=>setDepartureWay(w.value)} options={['Train', 'Car'].map(v=>{ return {value: v, label: v}})} />
        </div>
        <div />
      </Fragment> : <Fragment /> }

      {props.registrationFormType=='Guest Stay Summer 2024' ?
        <Fragment>
        <div>
          <label className='required'>For DEPARTURE: will you take one of the buses from Monte Sahaja to Faro or Lisbon airport, or will you arrange your own transport?</label>
          <Select required name="Dep_Transport_Type" className={departureWay!=null ? 'valid' : 'invalid'} onChange={(w)=>setDepartureWay(w.value)} options={['Bus to LISBON, arriving at the Airport around 13:30, 7 June', 'Bus to FARO, arriving at the Airport around 13:00, 7 June', 'I will arrange my own transport'].map(v=>{ return {value: v, label: v}})} />
        </div>
        <div />
      </Fragment> : <Fragment /> }

      {departureWay=='Car' ?
        <Fragment>
          <div>
            <label className='required'>Departure Time by Car</label>
            <Select required name="Car_Departure_Time" className={departureTimeCarValid ? 'valid' : 'invalid'} onChange={()=>setDepartureTimeCarValid(true)} options={['08:00-10:00', '10:00-12:00', '14:00-15:00', '15:00-17:00', '17:00-20:00'].map(v=>{ return {value: v, label: v}})} />
          </div>
        </Fragment> : <span></span>}

      {departureWay=='Train' ?
        <Fragment>
          <div>
            <label className='required'>Departure Time from Funcheira Station</label>
            <Select required name="Departure_Time" className={departureTimeTrainValid ? 'valid' : 'invalid'} onChange={()=>setDepartureTimeTrainValid(true)} options={['8:21 To Lisbon', '10:01 To Lisbon', '11:57 To Faro', '15:54 To Lisbon', '15:54 To Faro', '19:54 To Lisbon','20:24 To Faro'].map(v=>{ return {value: v, label: v}})} />
          </div>
        </Fragment> : <span></span>}         

      <h2>Dietary Preferences</h2>
      <p><b>Meals</b><br/>The meals are predominantly vegetarian, and we serve a good variety of grains, beans and vegetables. A few times per week, we also serve fish or chicken for those who would like a non-vegetarian option.</p>

      <p><b>Special Diets</b><br/>We are able to cater for vegan, gluten free, dairy free, soy free and nut free diets. If you require one of these diets, please speak with someone from the kitchen when you arrive. Please understand that we cannot provide diets more specific than this.</p>

      <p>Please inform the kitchen of any further medical dietary restrictions like food allergies by emailing <a href='mailto:sahajakitchen@mooji.org' target='_blank'>sahajakitchen@mooji.org</a> and reminding the Kitchen coordinator in person once you are in Sahaja.</p>

      <div className='dietary-choices'>
          <Form.Check type='checkbox' id='vegetarian' name="Vegetarian" value="true" label='I am a vegetarian'/>
          <Form.Check type='checkbox' id='fishChicken' name="Fish_Eater" value="true" label='I eat fish and/or chicken'/>
          <Form.Check type='checkbox' id='glutenFree' name="Gluten_Free" value="true" label='Gluten Free'/>
          <Form.Check type='checkbox' id='dairyFree' name="Dairy_Free" value="true" label='Dairy Free'/>
      </div>
      </Fragment> : <span></span>}

      <h2>Terms & Conditions</h2>
      <span className='form-field-info'>Please carefully read the <a href='https://documents.mooji.org/TC_Reception.pdf' target='_blank'>Terms and Conditions</a>.</span>
      <div className='terms'>
          <Form.Check type='checkbox' id='terms' name="Terms_reg" value="true" required label='I have read and agree to the Terms & Conditions.'/>
          <Form.Control type='hidden' name="Privacy_Policy" value="true" />	
      </div>      
    </Form>
  );
}