import React, { Fragment, useEffect, useState } from "react";
import Nav from "../components/Nav.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { genericErrorMsg, getRegistrationFormTypes, isString } from "../utils.js";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Select, { createFilter } from 'react-select';
import config from "../config.js";
import dayjs from 'dayjs';
import AsyncCreatableSelect from 'react-select/async-creatable';
import LoadingSpinner from "../components/LoadingSpinner.js";
import ConfirmationModal from "../components/ConfirmationModal.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export default function AddBookingPage() {

  let navigate = useNavigate();

  const zohoGuestTypeToNivasType = (zohoGuestType) => {
    if (!zohoGuestType) return null;
    if (zohoGuestType=='Team' || zohoGuestType=='Community Member' || zohoGuestType=='Rotating Team') return 'Team';
    else return 'Guest';
  }  

  const [searchParams, setSearchParams] = useSearchParams();
  const searchZohoId = searchParams.get('zohoId');
  const searchType = zohoGuestTypeToNivasType(searchParams.get('type'));
  const searchRate = searchParams.get('rate');
  const searchGuestPeriodType = searchParams.get('period');

  const [guests, setGuests] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [rates, setRates] = useState([]);
  const types = ['Team', 'Guest', 'Village'].map(t=> { return {value: t, label: t}});
  const statuses = ['Blocked', 'Confirmed'].map(t=> { return {value: t.toLowerCase(), label: t}});
  const registrationFormTypes = getRegistrationFormTypes().map(t=> { return {value: t, label: t}});
  const paymentSchedules = [
    {value: 'FullPaymentAtBooking', label: 'Full payment when booking'},
    {value: 'FirstMonthPaymentAtBooking', label: 'First month payment when booking'},
    {value: 'PayInSahaja', label: 'Pay in Sahaja'},
    {value: 'Monthly', label: 'Monthly'},
  ];
  
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedRate, setSelectedRate] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedRegistrationForm, setSelectedRegistrationForm] = useState(null);
  const [selectedPaymentSchedule, setSelectedPaymentSchedule] = useState(paymentSchedules[0].value);

  const [isFirstRoomQuarantine, setIsFirstRoomQuarantine] = useState(false);
  const [guestCanChooseRoomType, setGuestCanChooseRoomType] = useState(true);

  const [status, setSelectedStatus] = useState(searchType=='Team' ? 'confirmed' : 'blocked');

  const [roomBookings, setRoomBookings] = useState([{startDate: dayjs().toDate(), endDate: dayjs().add(6, 'day').toDate(), roomId: null}]);

  const [showCreateGuestModal, setShowCreateGuestModal] = useState(null);

  const [saving, setSaving] = useState(false);
  const [fetchingRooms, setFetchingRooms] = useState(false);

  const setRBRoom = (index, room) => {
    const newRoomBookings = [...roomBookings];
    newRoomBookings[index] = {startDate: roomBookings[index].startDate, endDate: roomBookings[index].endDate, roomId: room?.value};
    setRoomBookings(newRoomBookings);
  }

  const setRBDates = (index, startDate, endDate) => {
    const newRoomBookings = [...roomBookings];
    newRoomBookings[index] = {startDate: startDate, endDate: endDate, roomId: null};
    setRoomBookings(newRoomBookings);
  }

  const setRBStartDate = (index, date) => {
    const newRoomBookings = [...roomBookings];
    newRoomBookings[index] = {startDate: date, endDate: roomBookings[index].endDate, roomId: null};
    setRoomBookings(newRoomBookings);
  }

  const setRBEndDate = (index, date) => {
    const newRoomBookings = [...roomBookings];
    newRoomBookings[index] = {startDate: roomBookings[index].startDate, endDate: date, roomId: null};
    if (index==0 && newRoomBookings.length>1) newRoomBookings[1] = {startDate: date, endDate: roomBookings[index].endDate, roomId: null}
    setRoomBookings(newRoomBookings);
  }  

  const fetchGuests = () => {
    fetch(`${config.server_base_url}/api/guests`)
          .then((response) => response.json())
          .then((data) => {
            const guestsOptions = data.guests.map((g) => { return {value: g.id, label: `(${g.preferredName ?? g.firstName}) ${g.firstName} ${g.lastName}, Zoho Id: ${g.zohoId ?? 'none'}`}});
            setGuests(guestsOptions);
            if (searchZohoId!=null) setSelectedGuest(guestsOptions.find(g=>g.label.includes(searchZohoId)));
            if (searchType) setSelectedType(searchType);
            setSelectedRegistrationForm(searchGuestPeriodType && getRegistrationFormTypes().find(t=>t==searchGuestPeriodType) ? searchGuestPeriodType : 'default');
          });
  }

  const fetchFreeRooms = (index, startDate, endDate) => {
    setFetchingRooms(true);

    const searchParams = {
          filterNotBookedStartDate: dayjs(startDate).format('YYYY-MM-DD'),
          filterNotBookedEndDate: dayjs(endDate).format('YYYY-MM-DD'),
          includeOfflandRooms: true
    };
    if ((isFirstRoomQuarantine && index==1) || (!isFirstRoomQuarantine && index==0)) searchParams.filterRateGroup = selectedRate;

    return fetch(`${config.server_base_url}/api/rooms?` + new URLSearchParams(searchParams))
        .then((response) => response.json())
        .then((data) => {
          setFetchingRooms(false);
          return data.rooms.map(r=> { return { value: r.id, label: r.isOffland ? `${r.name} - offland` : r.name}})
        })
        .catch((err)=> toast.error( genericErrorMsg, {theme: 'colored'}))
  }

  const fetchRates = () => {
    fetch(`${config.server_base_url}/api/rateGroups`)
        .then((response) => response.json())
        .then((data) => {
          const rateOptions = data.rateGroups.map(r=> { return { value: r.name, label: r.name}});
          setRates(rateOptions);
          if (searchRate!=null) setSelectedRate(rateOptions.find(r=>r.value==searchRate)?.value)
        });
  }  

  const syncGuestWithZoho = () => {
    fetch(`${config.server_base_url}/api/guests/syncGuestWithZoho?zohoId=${searchZohoId}`)
        .then((response) => response.json())
        .then((data) => {
          fetchGuests();
          const startDate = data.guest.zoho.invArrivalDate ? dayjs(data.guest.zoho.invArrivalDate).toDate() : dayjs().toDate();
          const endDate = data.guest.zoho.invDepartureDate ? dayjs(data.guest.zoho.invDepartureDate).toDate() : dayjs().add(6, 'day').toDate();

          setRBDates(0, startDate, endDate);
        });
  }  

  useEffect(() => {
    if (searchZohoId!=null) {
      syncGuestWithZoho();
    } else {
      fetchGuests();
    }
    fetchRates();
  },[]) 

  useEffect(() => {
    if (selectedRate!=null) {
      fetchFreeRooms(0, roomBookings[0].startDate, roomBookings[0].endDate).then((rooms1)=> {
        const newRooms = [...rooms];
        newRooms[0] = rooms1;
        if (roomBookings.length>1) fetchFreeRooms(1, roomBookings[1].startDate, roomBookings[1].endDate).then((rooms2)=> {
          newRooms[1] = rooms2;
          setRooms(newRooms); 
        }); else setRooms(newRooms);
      });
    }
  },[roomBookings]) 

  useEffect(() => {
    roomBookings.forEach((rb, index)=>setRBRoom(index, null));
  },[selectedRate])   

  const saveBooking = () => {
    if (roomBookings.map((rb, i) => {
      if (dayjs(rb.startDate).isAfter(dayjs(rb.endDate))) {
        document.getElementById(`error${i}`).innerHTML = 'Start date cannot be after end date.';
        return false;
      }
      return true;
    }).includes(false)) return;

    setSaving(true);
    const startingBalanceFields = document.getElementsByName('startingBalance');

    fetch(`${config.server_base_url}/api/bookings`, 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            createGuest: isString(selectedGuest.value),
            guestId: isString(selectedGuest.value) ? selectedGuest.value.replace('_NEW_', '') : selectedGuest.value,
            rooms: roomBookings.map((rb, i)=> { return {
              startDate: dayjs(rb.startDate).format('YYYY-MM-DD'), 
              endDate:dayjs(rb.endDate).format('YYYY-MM-DD'),
              roomId: rb.roomId
            }}),
            type: selectedType,
            rate: selectedRate,
            paymentSchedule: selectedType=='Guest' || selectedType=='Village' ? selectedPaymentSchedule : 'Monthly',
            isFirstRoomQuarantine: isFirstRoomQuarantine,
            guestCanChooseRoomType: guestCanChooseRoomType,
            status: status,
            startingBalance: startingBalanceFields.length>0 && startingBalanceFields[0].value ? parseFloat(startingBalanceFields[0].value) : 0,
            registrationFormType: selectedType=='Guest' ? selectedRegistrationForm : 'default'

          })
        })
        .then((response) => response.json())
        .then((data) => { setSaving(false); if (data.success) navigate(`/bookings/${data.booking.id}`, { replace: false }); else toast.error(data.reason, {theme: 'colored'})  })
        .catch((error) => {
          setSaving(false);
          toast.error( genericErrorMsg, {theme: 'colored'})
        });        
  };

  const addAnotherRoom = () => {
    const newRoomBookings = [...roomBookings];
    const bookingEndDate = roomBookings[0].endDate;

    newRoomBookings[0].endDate = dayjs(newRoomBookings[0].startDate).add(4, 'day').toDate();
    newRoomBookings.push({startDate: newRoomBookings[0].endDate, endDate: bookingEndDate, room: null});
    setRoomBookings(newRoomBookings);
    fetchFreeRooms(newRoomBookings.length-1, newRoomBookings[newRoomBookings.length-1].startDate, newRoomBookings[newRoomBookings.length-1].endDate);
  }
  
  const removeLastRoom = () => {
    const newRoomBookings = [...roomBookings];
    newRoomBookings[0].endDate = dayjs(newRoomBookings[1].endDate).toDate();
    newRoomBookings[0].roomId = null;
    newRoomBookings.pop();
    setRoomBookings(newRoomBookings);
  }

  const searchGuests = async (text) => {
    if (text==null || text.length<3) return [];
    const words = text.split(' ');
    return guests.filter(g=>{
      const json = JSON.stringify(g).toLowerCase();
      return !(words.map(w=>json.includes(w.toLowerCase())).includes(false));
    });
  };
  
  const filterRooms = (roomOp, text, roomRowIdx) => {
    if (text.length==0) return rooms[roomRowIdx];
    const words = text.split(' ');
    return !(words.map(w=>roomOp.label.toLowerCase().includes(w.toLowerCase())).includes(false));
  };  

  const addNewGuest = (name) => {
    const newGuests = [...guests, {value: '_NEW_' + name, label: name}];
    setGuests(newGuests);
    setSelectedGuest(newGuests.find(g=>g.value=='_NEW_' + name));
  }

  return (
    <div id="app-container" className="app-container">
        <Nav url="/calendar"></Nav>
        <div className='page-header'><h1>Create Booking</h1></div>
        <div id="page-container" className="page-container booking-edit-page">
          <div className='booking-form'>
            {guests && guests.length ?
              <div className='form-row'>
                <label>Guest:</label>
                <AsyncCreatableSelect key={guests.length} onCreateOption={(name)=>setShowCreateGuestModal(name)} defaultValue={selectedGuest} onChange={setSelectedGuest} placeholder="Search Guest..." noOptionsMessage={()=> "Search by Name or Zoho ID..."} cacheOptions loadOptions={searchGuests} defaultOptions />
              </div> : <LoadingSpinner text='Loading Zoho records' /> }
            { selectedGuest!=null ?
              <Fragment>
                <div className='form-row type-row'>
                  <label>Type:</label>
                  <Select options={types} onChange={type => {setSelectedType(type.value); setSelectedStatus(type.value=='Team' ? 'confirmed' : 'blocked'); } } defaultValue={types.find(t=>t.value==selectedType)}/>
                </div> 
                { selectedType=='Guest' || selectedType=='Village' ?
                  <div className='form-row payment-schedule-row'>
                    <label>Payment Schedule:{selectedGuest.createGuest}</label>
                    <Select defaultValue={paymentSchedules[0]} options={paymentSchedules} onChange={s => setSelectedPaymentSchedule(s.value) } />
                  </div> : <span></span> }
                { selectedType && rates && rates.length ?
                  <div className='form-row rate-row'>
                    <label>Rate:</label>
                    <Select options={rates} onChange={rate => setSelectedRate(rate.value) } defaultValue={rates.find(t=>t.value==selectedRate)} />
                  </div> : <span></span>}
                { selectedRate!=null ?
                  <Fragment>
                    <h3>Rooms</h3>
                    <div className='form-row'>
                      <Form.Check type="checkbox" id='quarantine-check' label="Is first room Quarantine?" onChange={(e)=>{setIsFirstRoomQuarantine(e.target.checked); e.target.checked ? addAnotherRoom() : removeLastRoom()}} />
                    </div>
                    {roomBookings.map((rb, i)=>
                      <Fragment key={i}>
                        <p className='error' id={`error${i}`}></p>
                        <div className='form-row room-row'>
                          <div>
                            <label>From:</label>
                            <DatePicker selected={rb.startDate} onChange={(date) => setRBStartDate(i, date)} dateFormat="dd MMM, yyyy" calendarStartDay={1} disabled={i==1} />
                          </div>
                          <div>
                            <label>To:</label>
                            <DatePicker selected={rb.endDate} onChange={(date) => setRBEndDate(i, date)} minDate={rb.startDate} dateFormat="dd MMM, yyyy" calendarStartDay={1} />
                          </div>
                          <div>
                            <label>Room:{fetchingRooms ? <LoadingSpinner />:<Fragment />}</label>
                            <Select key={`${rb.startDate}-${rb.endDate}-${selectedRate}`} options={rooms[i]} onChange={room => setRBRoom(i, room) } filterOption={(candidate, input)=>filterRooms(candidate, input, i)} />
                            <span className='form-field-info'>Only available rooms for your dates are shown</span>
                          </div>
                      </div>
                    </Fragment>)}
                    {selectedType=='Guest' ?
                    <div className='form-row'>
                      <Form.Check type="checkbox" id='guest-can-choose-check' label="Guest can choose room type?" onChange={(e)=>setGuestCanChooseRoomType(e.target.checked)} checked={guestCanChooseRoomType} />
                    </div> : <span></span> }
                    {selectedType=='Guest' ?
                    <div className='form-row rate-row'>
                      <label>Registration Form:</label>
                      <Select options={registrationFormTypes} onChange={registrationForm => setSelectedRegistrationForm(registrationForm.value) } defaultValue={registrationFormTypes.find(t=>t.value==selectedRegistrationForm)} />
                    </div> : <span></span>}
                    <div className='form-row rate-row'>
                      <label>Status:</label>
                      <Select options={statuses} onChange={status => setSelectedStatus(status.value) } defaultValue={statuses.find(s=>s.value==status)} value={statuses.find(s=>s.value==status)} />
                      <span className='form-field-info'>Blocked rooms can still be reallocated depending on what guests choose when they book. Guests need to register to confirm a blocked booking.</span>
                    </div>  
                    {selectedType=='Guest' && status=='confirmed' ?
                    <div className='form-row'>
                      <label>Starting balance (€):</label>
                      <Form.Control type="number" step='any' name='startingBalance' />
                      <span className='form-field-info'>If the booking already has payments in Cloudbeds, enter the total paid amount here.</span>
                    </div> : <span></span> }
                  </Fragment> : <div></div> }
                </Fragment> : <div></div> }
              <div className='form-row action-buttons'>
                { roomBookings && !(roomBookings.map(rb=>rb.roomId!=null).includes(false)) ? 
                  <Button variant="primary" onClick={saveBooking} disabled={saving} className='mb-2'>Save {saving ? <LoadingSpinner/>: ""}</Button>
                  : <span></span> }
                <Button variant="secondary" onClick={()=> navigate("/calendar", { replace: false })} className='mb-2'>Cancel</Button>
              </div>
          </div>
          {showCreateGuestModal ? <ConfirmationModal title="Are you sure you want to create a guest ?" body={`Use this option only when there is no Zoho Guest record that you can use.`} onCancel={()=>setShowCreateGuestModal(null)} onConfirm={()=>addNewGuest(showCreateGuestModal)} /> : <span></span>}
        </div>
        <ToastContainer />
    </div>
  )
}