import React, { useEffect, useState, Fragment } from "react";

import config from "../config.js";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from "dayjs";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "../components/CheckoutForm.js";
import RegistrationForm from "../components/RegistrationForm.js";
import WelcomeMyBooking from "../components/WelcomeMyBooking.js";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { genericErrorMsg } from "../utils.js";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import LoadingSpinner from "../components/LoadingSpinner.js";

const stripePromise = loadStripe(config.stripe_public_key);

export default function MyBookingPage() {

    let { bookingId } = useParams();

    let navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const paymentCompleted = searchParams.get('paymentCompleted');

    const [booking, setBooking] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [nextPayment, setNextPayment] = useState(null);
    const [fullPayment, setFullPayment] = useState(null);
    const [selectedRate, setSelectedRate] = useState(null);
    const [selectedZeroRate, setSelectedZeroRate] = useState(false);
    
    const [step, setStep] = useState(1);

    const [loading, setLoading] = useState(false);

    const fetchBooking = () => {
        fetch(`${config.server_base_url}/api/my-booking/${bookingId}`)
            .then((response) => response.json())
            .then((data) => {
                setBooking(data.booking); 

                if ((data.booking.booking.status=='confirmed' && 
                    data.booking.booking.type=='Team') || (data.booking.booking.status=='confirmed' && 
                    data.booking.booking.type!='Team' && data.booking.booking.registrationComplete)) {
                    if (paymentCompleted) setStep(4);
                    else {
                        setStep(2);
                        if (data.booking.nextPayment) fetchStripeSecret(data.booking.booking.id); 
                    }
                } else if (data.booking.booking.status!='confirmed' && 
                        data.booking.booking.type!='Team') {
                    setStep(1);
                    if (data.booking.showRoomTypeRates.length==1) {
                        const rateId = data.booking.showRoomTypeRates[0].id;
                        const ratePrice = data.booking.showRoomTypeRates[0].rate.price;
                        setSelectedRate(rateId);
                        if (ratePrice!=0) fetchStripeSecret(data.booking.booking.id, rateId);
                        setSelectedZeroRate(ratePrice==0);
                    } else if (data.booking.nextPayment) fetchStripeSecret(data.booking.booking.id); 
                } else if (data.booking.booking.status=='confirmed' && 
                            data.booking.booking.type!='Team') {
                    setStep(data.booking.booking.registrationComplete ? 4 : 3); scrollToTop();
                }
            })
            .catch((err) => toast.error(genericErrorMsg, {theme: 'colored'}))
    }

    const fetchStripeSecret = (bookingId, rateId=null) => {
        fetch(`${config.server_base_url}/api/payments/create-payment-intent`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ bookingId: bookingId, rateId: rateId })
            })
            .then((response) => response.json())
            .then((data) => {setNextPayment(data.nextPayment); setFullPayment(data.fullPayment); return data;})
            .then((data) => setClientSecret(data.clientSecret));
    }

    const saveRegistration = (form) => {
        setLoading(true);

        var data = new FormData(form);

        fetch(`${config.server_base_url}/api/my-booking/${booking.booking.id}/register`, 
        {
            method: 'POST',
            body: data
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) { 
                setStep(4); scrollToTop();
            } else toast.error( data.reason, {theme: 'colored'})
            setLoading(false);
        })
        .catch((error) => {setLoading(false); toast.error( genericErrorMsg, {theme: 'colored'})});
    }

    useEffect(() => {
        fetchBooking();
    },[])

    const maybeGoToStep = (step) => {
        if (step==2) {//validate welcome form
            const welcomeForm = document.querySelector('form.welcome-my-booking-form');
            welcomeForm.classList.add('was-validated');

            if (welcomeForm.checkValidity()===true) {
                setStep(2); scrollToTop();
            }
        } else if (step==4) {//validate registration form
            const registrationForm = document.querySelector('form.registration-form');
            registrationForm.classList.add('was-validated');

            if (registrationForm.checkValidity()===true) {
                saveRegistration(registrationForm);
            } else {
                const invalidFields = document.getElementsByClassName('invalid');
                if (invalidFields.length) invalidFields[0].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            }
        }
    }

    const changeSelectedRate = (rateId, ratePrice)=> {
        setSelectedRate(rateId);
        if (ratePrice!=0) fetchStripeSecret(booking.booking.id, rateId);
        
        setSelectedZeroRate(ratePrice==0);
    }

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance
    };

    const submitBookingForm = () => {
        fetch(`${config.server_base_url}/api/my-booking/${booking.booking.id}/confirmBookingWithNoPayment`, 
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                roomTypeRateId: selectedRate
            })            
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) { 
                setStep(3); scrollToTop();
            } else toast.error( data.reason, {theme: 'colored'})})
        .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
    }

    const scrollToTop = () => {
        setTimeout(function() {window.scrollTo(0, 0);}, 10)
    }

    return (
    <div id='app-container' className="app-container my-booking-app">
        <div id='page-container' className="page-container my-booking-page with-page-header">  
            <div className='page-header'>
                <img className='mooji-logo' src="https://cdn.mooji.org/wp-content/themes/moojiorg/img/logo2.png" srcSet="https://cdn.mooji.org/wp-content/themes/moojiorg/img/logo2.png 1x, https://cdn.mooji.org/wp-content/themes/moojiorg/img/logo2@2x.png 2x" alt="Mooji" title="Mooji" />
            </div>
            {booking && booking.booking.type!='Team' ?
                <div>
                    <ul className="progressbar">
                        {['Start', 'Accommodation', 'Register', 'Done'].map((stepName, index) =>
                            <li key={index} className={step==index+1 ? 'active': ''}><span>{stepName}</span></li>
                        )}
                    </ul>
                </div> : <span></span> }
            { step==1 && booking ?
                <div key={step} className='booking-form welcome-form'>
                    <h1>WELCOME TO MONTE SAHAJA</h1>
                    <WelcomeMyBooking isVillageBooking={false} />
                    <div className='action-buttons'>
                        <Button variant="primary" onClick={()=>maybeGoToStep(2)} className='mb-2'>Continue</Button>
                    </div>
                </div> : <span></span>
            }
            { step==2 && booking ? 
            <div key={step} className='booking-form'>
                <h1>YOUR ACCOMMODATION IN MONTE SAHAJA</h1>
                <div className='form-row guest-name'>
                    <label>Your name</label>
                    <label className='form-value'>({booking.booking.guest.preferredName}) {booking.booking.guest.firstName} {booking.booking.guest.lastName}</label>
                </div>
                <div className='form-row dates-row'>
                    <div>
                        <label>Arrival Date</label>
                        <label className='form-value'>{dayjs(booking.booking.roomBookings[0].startDate).format('DD MMM YYYY')}</label> 
                    </div>
                    <div>
                        <label>Departure Date</label>
                        <label className='form-value'>{dayjs(booking.booking.roomBookings[booking.booking.roomBookings.length-1].endDate).format('DD MMM YYYY')}</label> 
                    </div>
                </div>
                {booking && booking.booking.status=='confirmed' ?
                    <Fragment>
                        <div className='form-row details-row'>
                            <div>
                                <label>Accommodation</label>
                                <label className='form-value'>{booking.booking.roomBookings[booking.booking.roomBookings.length-1].room.roomType.name}</label>
                            </div>
                            <div>
                                <label>Rate</label>
                                <label className='form-value'>€{booking.booking.roomBookings[booking.booking.roomBookings.length-1].rate.price} / {booking.booking.roomBookings[booking.booking.roomBookings.length-1].rate.isMonthly ? 'month' : 'day'}</label>
                            </div>
                        </div> 
                        {(booking.nextPayment) ?
                            (clientSecret ?
                            <div className='form-row'>
                                <h3>You need to pay <strong>€{booking.nextPayment.amount}</strong> today for the period {dayjs(booking.nextPayment.startDate).format('DD MMM YYYY')} - {dayjs(booking.nextPayment.endDate).format('DD MMM YYYY')}. </h3>
                                <Elements stripe={stripePromise} options={options} key={clientSecret}>
                                    <CheckoutForm bookingId={booking.booking.id} bookingType={booking.booking.type} billingAddress={booking.booking.billingAddress} amount={booking.nextPayment.amount} returnUrl={`${config.server_base_url}/api/payments/completed`}/>
                                </Elements>
                            </div> : 
                            <span></span>) 
                            :
                            <div className='form-row'>
                                <h3>You have no due payments.</h3>
                            </div>
                        }
                    </Fragment> :
                    <Fragment>
                        {booking.showRoomTypeRates.length>1 ? <h3>Please choose the type of room you would like to stay in:</h3> : <h3></h3>}
                        <div className='form-row room-type-choice'>
                            {booking.showRoomTypeRates.map((rtRate) =>
                                <div key={rtRate.roomType.id} className="room-type-card" onClick={(e)=>e.target.closest('.room-type-card').querySelector('input[type=radio]').click()}>
                                    <div className="room-type-photo" onClick={(e)=>e.stopPropagation()}>
                                        <ImageGallery lazyLoad={true} showBullets={true} showPlayButton={false} items={(rtRate.roomType.photos && JSON.parse(rtRate.roomType.photos).length ? JSON.parse(rtRate.roomType.photos) : ['no_image.png']).map(p=>{
                                            return {
                                                original: `${config.server_base_url}/static/uploads/${p}`,
                                                //thumbnail: `${config.server_base_url}/static/uploads/${p}`
                                            }
                                        })} />
                                    </div>
                                    <div className="room-type-info">
                                        <h3>{ rtRate.roomType.name }</h3>
                                        <div dangerouslySetInnerHTML={{ __html: rtRate.roomType.description }} />
                                    </div>
                                    <div className="room-type-price">
                                        <Form.Check type='radio' id={rtRate.roomType.id + '-choice'}>
                                            <Form.Check.Input type='radio' isValid name='room-type-choice' value={rtRate.id} checked={rtRate.id==selectedRate} onChange={(e)=>changeSelectedRate(e.target.value, rtRate.rate.price)}  />
                                            <Form.Check.Label htmlFor={rtRate.roomType.id + '-choice'}>
                                                <h4>€{ rtRate.rate.price } / night</h4>
                                            </Form.Check.Label>                                    
                                        </Form.Check>
                                    </div>
                                </div>
                            )}
                        </div>
                        {clientSecret && nextPayment ? 
                            <div className='form-row'>
                                <h3>You need to pay <strong>€{nextPayment.amount}</strong> today. </h3>
                                <Elements stripe={stripePromise} options={options} key={clientSecret}>
                                    <CheckoutForm bookingId={booking.booking.id} amount={nextPayment.amount} bookingType={booking.booking.type} billingAddress={booking.booking.billingAddress} returnUrl={`${config.server_base_url}/api/payments/completed`}/>
                                </Elements>
                            </div>:<span></span>}
                        {selectedRate && fullPayment && booking.booking.paymentSchedule=='PayInSahaja' ? 
                            <div className='form-row'>
                                <h3>The total amount for your stay is €{fullPayment.amount}. You can pay in person during your visit in Monte Sahaja.</h3>

                                <CheckoutForm payment={false} onBillingAddressSaved={(data)=>submitBookingForm()} bookingId={booking.booking.id} amount={fullPayment.amount} bookingType={booking.booking.type} billingAddress={booking.booking.billingAddress} />
                            </div> :<span></span>}
                        {selectedRate && selectedZeroRate ? 
                            <div className='form-row'>
                                <h3>The total amount for your stay is €0.</h3>
                                <Button className='submit-booking-button' onClick={()=>submitBookingForm()} type='submit'>Continue</Button>
                            </div> :<span></span>}                            
                    </Fragment>
                    }
            </div> : <span></span>}
            { step==3 && booking ? 
                <div key={step} className='booking-form'>
                    <h1>REGISTRATION</h1>
                    <RegistrationForm isOpenBooking={booking.booking.isOpenBooking} registrationFormType={booking.booking.registrationFormType} />
                    <div className='action-buttons'>
                        <Button variant="primary" onClick={()=>{maybeGoToStep(4)}} disabled={loading} className='mb-2'>Continue {loading ? <LoadingSpinner /> : ""}</Button>
                    </div>                    
                </div> : <span></span>
            }
            { step==4 ? 
                <div key={step} className='booking-form'>
                    <h1>Thank you!</h1>
                    <img style={{maxWidth: '200px', margin: '0 auto', display: 'block'}} src="https://shopcdn.mooji.org/wp-content/uploads/2020/04/e258357b434764c55866c81dde28ce54.jpg" />
                </div> : <span></span>
            }
        </div>
        <ToastContainer />
      </div>
    )
  }