import React from "react";
import Form from 'react-bootstrap/Form';

export default function GuestDetailsFields(props) {

  const secondGuestRequired = props.secondGuestRequired;

  const validatePhone = (el)=> {
    var regexp = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
    var isValid = regexp.test(el.value);

    el.setCustomValidity(isValid ? '' : 'Please enter a valid phone number');
    el.classList.toggle('invalid', !isValid);
  }  

  return (
    !props.isSecondGuest ? 
      <div className='guest-details-fields'>
        <div>
            <label className='required'>First Name</label>
            <Form.Control name="firstName" type="text" required />
        </div>
        <div>
            <label className='required'>Last Name</label>
            <Form.Control name="lastName" type="text" required />
        </div>
        <div>
            <label className='required'>Email</label>
            <Form.Control name="email" type="email" required />
        </div>      
        <div>
            <label>Phone</label>
            <Form.Control name="phone" type="text" onChange={(e)=>validatePhone(e.target)} />
            <span className='form-field-info'>Start with + sign and country code. Example: +351 203 645 5353</span>
        </div>      
      </div> :
      <div className='guest-details-fields'>
        <div>
            <label className={`${secondGuestRequired ? 'required' : ''}`}>First Name</label>
            <Form.Control name="firstName" type="text" required={secondGuestRequired} />
        </div>
        <div>
            <label className={`${secondGuestRequired ? 'required' : ''}`}>Last Name</label>
            <Form.Control name="lastName" type="text" required={secondGuestRequired} />
        </div>
      </div>      
  );
}