import React, { Fragment, useEffect, useState, useMemo } from "react";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from "react-bootstrap/esm/Button.js";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup.js";
import { TfiArrowCircleRight } from "react-icons/tfi"
import ChangeRoomBookingModal from "./ChangeRoomBookingModal.js";
import ChangeRoomBookingDatesModal from "./ChangeRoomBookingDatesModal.js";
import {MdMode} from "react-icons/md";
import ChangeCleaningStatusModal from "./ChangeCleaningStatusModal.js";
import _ from "lodash";

export default function BookingsCalendar(props) {

  dayjs.extend(isBetween);
  dayjs.extend(utc);

  let navigate = useNavigate();

  const [bookings, setBookings] = useState(props.bookings);
  const [roomTypes, setRoomTypes] = useState(props.firstCalendarPaint ? [Object.assign(props.roomTypes[0], {"locations": props.roomTypes[0].locations})] : props.roomTypes);

  const [popoverClickedDate, setPopoverClickedDate] = useState(null);
  const [popoverGuestName, setPopoverGuestName] = useState(null);
  const [popoverRoomBooking, setPopoverRoomBooking] = useState(null);
  const [popoverRoomTypeId, setPopoverRoomTypeId] = useState(null);
  const [showChangeRoomBookingModal, setShowChangeRoomBookingModal] = useState(false);
  const [showChangeRoomBookingDatesModal, setShowChangeRoomBookingDatesModal] = useState(false);
  const [showChangeCleaningStatusModal, setShowCleaningStatusModal] = useState(false);

  const [cleaningStatusPopoverProps, setCleaningStatusPopoverProps] = useState(null);

  const offlandDates = ((bookings)=> {
    return _.groupBy(bookings.flatMap(b=>b.offlandDates.filter(od=>
      dayjs(od.startDate).isBefore(dayjs(props.endDate)) && dayjs(od.endDate).isAfter(dayjs(props.startDate)))), (od)=>od.bookingId)
  })(bookings)

  const bookingsById = ((bookings) => _.mapValues(_.groupBy(bookings, (b)=>b.id), (arr)=>arr[0]))(bookings)
  
  const findOffland = (roomBooking, dayjsDate) => {
    return offlandDates[roomBooking.bookingId]?.find(od=>
      dayjsDate.isBetween(dayjs(od.startDate), dayjs(od.endDate), 'day', '[]'));
  }

  const getDaysForHeader = (() => {
    const dates = [];
    var currentDate = dayjs(props.startDate).startOf('day');
    while (currentDate.isBefore(dayjs(props.endDate).startOf('day').add(1, 'day'))) {
      dates.push(currentDate.toDate());
      currentDate = currentDate.add(1, 'day');
    }
    return dates;
  })();

  const dateToDay = (dt) => {
    return dayjs(dt).format('YYYY-MM-DD');
  }

  const bookingDuration = (startDate, endDate) => {
    if (dayjs(endDate).startOf('day').isAfter(dayjs(props.endDate).startOf('day'))) endDate = dayjs(props.endDate).startOf('day').toDate();
    if (dayjs(startDate).startOf('day').isBefore(dayjs(props.startDate).startOf('day'))) startDate = dayjs(props.startDate).startOf('day').toDate();

    return dayjs(endDate).startOf('day').diff(dayjs(startDate).startOf('day'), 'day') + 1;
  }

  const hidePopover = () => {
    document.getElementsByClassName('booking-menu-popover')[0].style.display="none";
  }

  const hideCleaningStatusPopover = () => {
    document.getElementsByClassName('cleaning-status-popover')[0].style.display="none";
  }

  const popover = (props, clickedDate)=>(
    <Popover className="booking-menu-popover">
      <Popover.Body>
        <ButtonGroup vertical>
          <Button variant="light" onClick={()=>navigate(`/bookings/${props.bookingId}`, { replace: false }) }>Open Booking</Button>
          {!(dayjs(clickedDate).isSame(dayjs(props.roomBooking.endDate), 'day')) ?
            <Button variant="light" onClick={()=>{hidePopover(); setPopoverProps(props); setPopoverClickedDate(dayjs(clickedDate).format('YYYY-MM-DD')); setShowChangeRoomBookingModal(true)} }>Change Room/Rate from {dayjs(clickedDate).format('DD MMM')}</Button> : <span></span>
          }
          <Button variant="light" onClick={()=>{hidePopover(); setPopoverProps(props); setShowChangeRoomBookingDatesModal(true)} }>Change Dates</Button>
          {/* <Button variant="light" onClick={()=>navigate(`/my-booking/${props.bookingId}`, { replace: false }) }>Open <em>My Booking</em></Button> */}
        </ButtonGroup>  
      </Popover.Body>
    </Popover>
  );

  const setPopoverProps = (props) => {
    setPopoverGuestName(props.guestName);        
    setPopoverRoomBooking(props.roomBooking);
    setPopoverRoomTypeId(props.roomTypeId);
  }

  function RoomBooking(props) {   
    var offlandStartIndexes = [];   
    return (
      <div id={"rb-" + props.roomBooking.id} style={{width: (props.duration*(props.selectedView=='Day' ? 40 : 20)) + "px"}} onDoubleClick={()=>navigate(`/bookings/${props.bookingId}`, { replace: false }) }>
        {[...Array(props.duration)].map((e, i)=> {
          const currentDay = dayjs(props.clickedDate).add(i, 'day');
          const offland = findOffland(props.roomBooking, currentDay);

          if (offland!=null && currentDay.isSame(dayjs(offland.startDate), 'day') && !currentDay.isSame(dayjs(props.roomBooking.endDate), 'day')) offlandStartIndexes.push(i);

          return <OverlayTrigger rootClose key={i} trigger="click" placement="top" overlay={popover(props, currentDay.format('YYYY-MM-DD'))} delayShow={0} delayHide={0}>
            <div className={`booking-cell ${offland!=null ? 'offland' : ''}`}></div>
          </OverlayTrigger>
        })}
        <span className={`booking-band ${props.status} ${props.type}`}><span className='booking-info'>{props.guestName}</span></span>
        {offlandStartIndexes.map((offlandStartIndex, idx) =>
          <span key={idx} style={{marginLeft: offlandStartIndex*(props.selectedView=='Day' ? 40 : 20) + "px"}} className={`booking-band ${props.status} ${props.type} offland`}><span className='booking-info'>Offland</span></span>
        )}
      </div>
    );
  }
  
  const cleaningStatusPopover = (props)=> {
    return <Popover className="cleaning-status-popover">
      <Popover.Body>
        <span className={`cleaning-status ${props.room.cleaningStatus}`}>{props.room.cleaningStatus}</span> since {dayjs.utc(props.room.cleaningStatusUpdatedAt).format('DD MMM')} <Button style={{marginTop: '-5px'}} variant='link' onClick={()=>{ hideCleaningStatusPopover(); setCleaningStatusPopoverProps({room: props.room}); setShowCleaningStatusModal(true); }}><MdMode /></Button>
      </Popover.Body>
    </Popover>
  };

  function CleaningStatus(props) {
    return <OverlayTrigger rootClose trigger="click" placement="top" overlay={cleaningStatusPopover({room: props.room})} delayShow={0} delayHide={0}>
      <span className={`cleaning-status ${props.room.cleaningStatus}`}>{props.room.cleaningStatus.slice(0, 1)}</span>
    </OverlayTrigger>
  }

  useEffect(() => {
      setRoomTypes(props.roomTypes);
  });

  return (
    <div className='bookings-calendar'>
      {useMemo(()=>
      <table className={'bookings-table view-' + props.selectedView} cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            <th>Room</th>
            {getDaysForHeader.map((dt, index) => {
            const weekday = dt.toLocaleDateString('en-GB', { weekday: 'short' });

            if (props.selectedView=='Day') {
              const today = dayjs(dt).isSame(dayjs(), 'day');
              return <th key={index} className={(today ? ' today': '')}>
                <span>{weekday}</span>
                <span>{dt.getDate()}</span>
                <span>{dt.toLocaleDateString('en-GB', { month: 'short' })}</span>
              </th>
            } else if (props.selectedView=='Week') {
              const today = dayjs(dt).isSame(dayjs(), 'week');
              var weekSpan = 7;
              if (index==0) weekSpan = 8 - dayjs(dt).day();
              const weekStart = dayjs(dt);
              const weekEnd = dayjs(dt).add(weekSpan - 1, 'day');
              return weekday=='Mon' || index==0 ? <th key={index} colSpan={weekSpan} className={(today ? ' today': '')}>
                <span>{`${weekStart.date()} ${weekStart.format('MMM')}`} - {`${weekEnd.date()} ${weekEnd.format('MMM')}`}</span>
              </th> : "";
            }
          })}
          </tr>
        </thead>
          {roomTypes && roomTypes.length > 0 && bookings && roomTypes.filter(roomType=>props.roomTypesFilter.length==0 || props.roomTypesFilter.includes(roomType.id)).map((roomType) => (
            roomType.locations.filter(location=>props.locationsFilter.length==0 || props.locationsFilter.includes(location.id)).map((location) => (
              <tbody id={roomType.id + '-' +location.id} key={roomType.id + '-' +location.id} className='open'>
                {location.rooms.filter(room=>
                  (props.isBookableFilter ? room.isBookable : true) &&
                  (props.isFreeFilter ? room.confirmedRoomBookings.length==0 && room.blockedRoomBookings.length==0 && room.connectedRoomBookings.length==0 : true) && 
                  (props.isBlockedFilter ? [...room.confirmedRoomBookings, ...room.blockedRoomBookings, ...room.connectedRoomBookings].find(rb=>bookingsById[rb.bookingId].status=='blocked') : true) &&
                  (props.isConfirmedFilter ? [...room.confirmedRoomBookings, ...room.blockedRoomBookings, ...room.connectedRoomBookings].find(rb=>bookingsById[rb.bookingId].status=='confirmed') : true) &&
                  (props.isHKFilter ? (room.cleaningStatus!='prepared') && ([...room.confirmedRoomBookings, ...room.blockedRoomBookings, ...room.connectedRoomBookings].find(rb=>dayjs(rb.startDate).isBetween(dayjs(), dayjs().add(3, 'day'), 'day', []))) : true)
                ).map((room, index) => {
                  const allRoomBookingsForRoom = [...room.confirmedRoomBookings, ...room.blockedRoomBookings, ...room.connectedRoomBookings];
                  return <Fragment key={room.id}>
                    {index==0 ? <tr><td onClick={(e)=>e.target.closest('tbody').classList.toggle('open')}> <TfiArrowCircleRight /> {location.name} - {roomType.name}</td><td colSpan={getDaysForHeader.length}></td></tr> : <Fragment></Fragment>}
                    <tr key={room.id} className="room-row">
                      <td>{room.name}{props.hk && room.cleaningStatus!='used' ? <CleaningStatus room={room} /> : ""}</td>
                      
                      {getDaysForHeader.map((dt, index) => {
                        const roomBookings = allRoomBookingsForRoom
                          .filter(rb => dayjs(dt).isBetween(dayjs(rb.startDate), dayjs(rb.endDate), 'day', []))
                          .filter(roomBooking=> {
                            if (index==0 && dateToDay(dt) == roomBooking.endDate) return false;//if booking ends on the first day of the calendar, don't show
                            const bookingStartDate = new Date(Math.max(new Date(roomBooking.startDate).getTime(), props.startDate.getTime()));
                            return (dateToDay(dt)==dateToDay(bookingStartDate));
                          });

                        if (roomBookings.length>0) {
                          return <td key={index} style={{height: roomBookings.length*30}}>
                            {roomBookings.map((roomBooking, rbIndex)=> {
                              const booking = bookingsById[roomBooking.bookingId];
                              const duration = bookingDuration(new Date(roomBooking.startDate), new Date(roomBooking.endDate));
                              const startDateFormat = dayjs(roomBooking.startDate).isSame(dayjs(roomBooking.endDate), 'month') ? 'DD' : 'DD MMM';
                              return <RoomBooking key={roomBooking.id} selectedView={props.selectedView} status={booking.status} type={booking.type} clickedDate={dt} duration={duration} guestName={`${booking.guest.preferredName} ${booking.guest.lastName.slice(0,3)}. (${dayjs(roomBooking.startDate).format(startDateFormat)}-${dayjs(roomBooking.endDate).format('DD MMM')})`} bookingId={booking.id} roomBooking={roomBooking} roomTypeId={roomType.id}/>
                            })}
                          </td>
                        } else {
                          return <td key={index}></td>
                        }
                      })}
                    </tr>      
                  </Fragment>      
                })}
              </tbody>
            )
          )))}
      </table>
      , [bookings, roomTypes])}
      {showChangeRoomBookingModal ? <ChangeRoomBookingModal guestName={popoverGuestName} startDate={popoverRoomBooking.startDate} endDate={popoverRoomBooking.endDate} clickedDate={popoverClickedDate} roomBookingId={popoverRoomBooking.id} roomId={popoverRoomBooking.roomId ?? popoverRoomBooking.blockedRoomId} roomRateId={popoverRoomBooking.rateId} roomTypeId={popoverRoomTypeId} roomRateGroup={popoverRoomBooking.rateGroupName} isQuarantineRoom={popoverRoomBooking.isQuarantineRoom} isBlockedRoom={popoverRoomBooking.blockedRoomId!=null} onCancel={()=>setShowChangeRoomBookingModal(false)} onSave={()=>props.onChange()}/> : <span></span>}
      {showChangeRoomBookingDatesModal ? <ChangeRoomBookingDatesModal guestName={popoverGuestName} clickedDate={popoverClickedDate} roomBooking={popoverRoomBooking} onCancel={()=>setShowChangeRoomBookingDatesModal(false)} onSave={()=>{props.onChange()} }/> : <span></span>}
      {showChangeCleaningStatusModal ? <ChangeCleaningStatusModal {...cleaningStatusPopoverProps} onCancel={()=>setShowCleaningStatusModal(false)} onSave={()=>props.onChange()}/> : <span></span>}
    </div>
  )
}