import React, { useState, useMemo } from "react";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import { Country, State }  from 'country-state-city';

export default function BillingAddressFields(props) {

  const countries = useMemo(() => Country.getAllCountries().map(c=>{ return {value: c.isoCode, label: c.name}}), [])

  const [states, setStates] = useState(props.billingAddress ? State.getStatesOfCountry(countries.find(c=>c.label==props.billingAddress.country)?.value).map(s=>{return {value: s.name, label: s.name}}) : []);

  const [country, setCountry] = useState(props.billingAddress ? countries.find(c=>c.label==props.billingAddress.country)?.value : null);
  const [state, setState] = useState(props.billingAddress ? props.billingAddress.state : null);

  const singleCountryOption = () => country!=null ? [{value: Country.getCountryByCode(country).name, label: Country.getCountryByCode(country).name}] : [];

  const validateNIF = (value) => {
    if (!value || typeof value !== "string" || value.length !== 9) return false;
    let sumAux = 0;
    for (let i = 9; i >= 2; i--) {
      sumAux += i * (parseInt(value[value.length - i]) || 0);
    }
    const module = sumAux % 11;
  
    // Get the eight first numbers
    const NIFwithoutLastDigit = value.slice(0, value.length - 1);
  
    if (module === 0 || module === 1) {
      return `${NIFwithoutLastDigit}0` === value;
    } else {
      return `${NIFwithoutLastDigit}${11 - module}` === value;
    }
  };

  return (
    <div className='billing-address-fields'>
      <div className="street-address">
          <label className='required'>Street address</label>
          <div>
            <Form.Control name="streetAddress1" type="text" defaultValue={props.billingAddress?.streetAddress1} required placeholder="House number and street name" />
            <Form.Control name="streetAddress2" type="text" defaultValue={props.billingAddress?.streetAddress2} placeholder="Apartment, suite, unit etc. (optional)" />
          </div>
      </div>
      <div>
          <label className='required'>Country</label>
          <Select required name="country" className={country!=null ? 'valid' : 'invalid'} onChange={(c)=>{setCountry(c.value); setState(null); setStates(State.getStatesOfCountry(c.value).map(s=>{return {value: s.name, label: s.name}}))}} value={countries.find(c=>c.value==country)} options={countries} />
      </div> 
      <div>
          <label className='required'>State</label>
          <Select required key={country} name="state" className={state!=null ? 'valid' : 'invalid'} onChange={(c)=>setState(c.value)} value={states.find(s=>s.value==state)} options={states.length ? states : singleCountryOption()} />
      </div>
      <div>
          <label className='required'>Town / City</label>
          <Form.Control name="city" type="text" defaultValue={props.billingAddress?.city} required />
      </div>        
      <div>
          <label className='required'>Postcode / ZIP</label>
          <Form.Control name="postCode" type="text" defaultValue={props.billingAddress?.postCode} required />
      </div>        
      <div>
          <label className={props.bookingType=='Team' ? 'required' : ''}>Tax Number</label>
          <Form.Control required={props.bookingType=='Team'} defaultValue={props.billingAddress?.taxNumber} name="taxNumber" type="text" placeholder="DNI / NIF / NIE (optional)" onChange={(e)=>e.target.setCustomValidity(country=='PT' && e.target.value.trim().length && !validateNIF(e.target.value) ? 'Invalid NIF' : '' )}/>
      </div>        
    </div>
  );
}