import dayjs from 'dayjs';
import { Fragment, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import config from "../config.js";
import { genericErrorMsg } from "../utils.js";
import Select from 'react-select';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function AddRoomTypeModal(props) {
    
    const [errorMsg, setErrorMsg] = useState(null);

    const [photos, setPhotos] = useState(props.roomTypePhotos ? props.roomTypePhotos : []);
    const [description, setDescription] = useState(props.roomTypeDescription ?? '');

    const drag = (e, index) => {
        e.dataTransfer.setData("text", index);
    }

    const drop = (e, index) => {
        const draggedIndex = parseInt(e.dataTransfer.getData("text"));
        const draggedPhoto = photos[draggedIndex];
        
        const newOrder = [...photos].toSpliced(draggedIndex, 1);
        newOrder.splice(index, 0, draggedPhoto);

        setPhotos(newOrder);
    }

    const isString = (photo) => {
        return (typeof photo === 'string') || (photo instanceof String);
    }

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.add-room-type-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {
            var data = new FormData();
            data.append('name', document.getElementsByName('name')[0].value);
            data.append('description', description);
            data.append('maxOccupants', parseInt(document.getElementsByName('maxOccupants')[0].value));
            photos.forEach((photo, index)=> {
                if (!isString(photo)) data.append(`photo[]`, photo);
            });
            data.append('all_photos', JSON.stringify(photos.map(photo=>isString(photo) ? photo : '')));

            fetch(`${config.server_base_url}/api/roomTypes` + (props.roomTypeId ? `/${props.roomTypeId}`: ''), 
            {
              method: 'POST',
              body: data
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    useEffect(()=> {
        document.getElementsByName('name')[0].focus();
    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='add-room-type-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{props.roomTypeId ? 'Update' : 'Add'} Room Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='add-room-type-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div>
                            <label>Name:</label>
                            <Form.Control type="text" required name='name' defaultValue={props.roomTypeName} />
                        </div>
                        <div>
                            <label>Photos:</label>
                            <div className='form-field-info'>Max 500KB per photo. You can drag photos to reorder them</div>
                            <div className='photo-previews'>
                                {photos.map((image, index) => {
                                    return <div key={index} draggable={true} onDragStart={(e) => drag(e, index)} onDrop={(e)=>drop(e,index)} onDragOver={(e)=> e.preventDefault()}>
                                                {isString(image) ? 
                                                    <img key={index} src={config.server_base_url + "/static/uploads/" + image} /> :
                                                    <img key={index} src={URL.createObjectURL(image)} />}
                                                <button className='btn-close btn-remove' type='button' aria-label='Delete' onClick={()=> setPhotos([...photos].toSpliced(index, 1))}></button>
                                            </div>
                                })}
                            </div>
                            <Form.Control multiple id="photo" style={{display: 'none'}} type="file" accept="image/jpeg" name='photo' onChange={(e)=> {
                                var clone = [...photos];
                                for (var i = 0; i < e.target.files.length; i++) {
                                    clone.push(e.target.files[i]);    
                                }
                                setPhotos(clone);
                            }} />
                            <Button className='btn-add-photos' variant="primary" onClick={()=>document.getElementById('photo').click()}>Add photos</Button>
                        </div>
                        <div>
                            <label>Description:</label>
                            <ReactQuill theme="snow" value={description} onChange={setDescription} />
                        </div>
                        <div>
                            <label>Max Occupants:</label>
                            <Form.Control type="number" required name='maxOccupants' defaultValue={props.roomTypeMaxOccupants ?? 1} />
                        </div>                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}