import React, { useEffect, useState } from "react";

import Nav from "../components/Nav.js";
import config from "../config.js";
import { genericErrorMsg, isAdmin, isSuperAdmin } from "../utils.js";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {IoAddOutline} from "react-icons/io5";
import AddLocationModal from "../components/AddLocationModal.js";
import ConfirmationModal from "../components/ConfirmationModal.js";
import {MdMode, MdDelete} from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export default function LocationsPage() {

    const [locations, setLocations] = useState([]);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [selectedLocation, setSelectedLocation] = useState(null);

    const fetchLocations = () => {
        fetch(`${config.server_base_url}/api/locations`)
            .then((response) => response.json())
            .then((data) => setLocations(data.locations))
            .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
    }    

    const deleteLocation = (locationId) => {
        fetch(`${config.server_base_url}/api/locations/${locationId}`, 
            {
              method: 'DELETE',
              headers: { 'Content-Type': 'application/json' },
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) fetchLocations() })
            .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
      }
    useEffect(() => {
        fetchLocations();
    },[])

    return (
      <div id='app-container' className="app-container">
        <Nav url="/locations"></Nav>
        <div className='page-header'><h1>Locations</h1></div>
        <div id='page-container' className="page-container locations-page">  
            <div className='data-table-container'>
                <div className='table-actions'>
                    <Button variant="primary" onClick={()=>{setSelectedLocation(null); setShowAddModal(true); }} disabled={!(isAdmin() || isSuperAdmin())}><IoAddOutline /> Create</Button>
                </div>
                <Table striped bordered hover className='locations-table'>
                    <tbody>
                        {locations && locations.length > 0 && locations.map((location) => (
                            <tr key={location.id}>
                                <td>{location.name}</td>
                                <td className='row-actions'>
                                    <Button variant='light' onClick={()=> {setShowAddModal(true); setSelectedLocation({locationId: location.id, locationName: location.name}); }}><MdMode /></Button>
                                    <Button variant='light' onClick={()=>{setShowDeleteModal(true); setSelectedLocation({locationId: location.id, locationName: location.name});}} disabled={!(isAdmin() || isSuperAdmin())}><MdDelete /></Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
        {showAddModal ? <AddLocationModal {...selectedLocation} onCancel={()=>setShowAddModal(false)} onSave={()=>fetchLocations()} /> : <span></span>}
        {showDeleteModal ? <ConfirmationModal title={`Are you sure you want to delete <em>${selectedLocation.locationName}</em> ?`} body="This is irreversible." onCancel={()=>setShowDeleteModal(false)} onConfirm={()=>deleteLocation(selectedLocation.locationId)} /> : <span></span>}
        <ToastContainer />
      </div>
    )
  }