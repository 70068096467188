import React, { Fragment, useEffect, useState, useRef } from "react";
import Nav from "../components/Nav.js";
import config from "../config.js";
import { genericErrorMsg } from "../utils.js";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function LoginPage() {

    return (
        <div id='app-container' className="app-container login-app">
            <div id='page-container' className="page-container login-page">  
                <div className='login-box'>
                    <img src={`${config.server_base_url}/static/img/nivas-logo-black.png`}/>
                    <a className='social-sign-in google' href={`${config.server_base_url}/auth/google`}><span>Login with Google</span></a>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
  }