import { Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import config from "../config.js";
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { genericErrorMsg } from "../utils.js";
import LoadingSpinner from './LoadingSpinner.js';

export default function ChangeZohoIDModal(props) {
    
    const [errorMsg, setErrorMsg] = useState(null);

    const [saving, setSaving] = useState(false);

    const save = (e) => {
        e.preventDefault();

        const form = document.querySelector('.change-zoho-id-form');
        form.classList.add('was-validated');
    
        if (form.checkValidity()===true) {
            setSaving(true);
            const zohoId = document.getElementsByName('zohoId')[0].value;
            fetch(`${config.server_base_url}/api/guests/syncGuestWithZoho?zohoId=${zohoId}&guestId=${props.booking.guest.id}`)
            .then((response) => response.json())
            .then((data) => { setSaving(false); if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
            .catch((error) => {setSaving(false); toast.error( genericErrorMsg, {theme: 'colored'})});
        }
    }

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Zoho ID</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate className="change-zoho-id-form" onSubmit={save}>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div>
                            <label>Zoho ID:</label>
                            <Form.Control type="text" required name='zohoId' />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save{saving ? <LoadingSpinner/> : ''}</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}