import { Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import config from "../config.js";
import BillingAddressFields from './BillingAddressFields.js';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { Country }  from 'country-state-city';

export default function ChangeBillingAddressModal(props) {
    
    const [errorMsg, setErrorMsg] = useState(null);

    const save = (e) => {
        e.preventDefault();

        const addressForm = document.querySelector('.change-billing-address-form');
        addressForm.classList.add('was-validated');
    
        if (addressForm.checkValidity()===true) {
          fetch(`${config.server_base_url}/api/my-booking/${props.booking.id}/saveBillingAddress`, 
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ 
                streetAddress1: document.getElementsByName('streetAddress1')[0].value,
                streetAddress2: document.getElementsByName('streetAddress2')[0].value,
                city: document.getElementsByName('city')[0].value,
                country: Country.getCountryByCode(document.getElementsByName('country')[0].value).name,
                state: document.getElementsByName('state')[0].value,
                postCode: document.getElementsByName('postCode')[0].value,
                taxNumber: document.getElementsByName('taxNumber')[0].value
              })          
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
    }

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Billing Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate className="change-billing-address-form" onSubmit={save}>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <BillingAddressFields billingAddress={props.booking.billingAddress} bookingType={props.booking.type} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}