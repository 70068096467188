import React, { Fragment, useEffect, useState, useRef } from "react";
import Nav from "../components/Nav.js";
import config from "../config.js";
import { genericErrorMsg, isAdmin, isSuperAdmin } from "../utils.js";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import {IoAddOutline, IoRemoveOutline} from "react-icons/io5";

export default function SettingsPage() {

    const [errorMsg, setErrorMsg] = useState(null);

    const [settings, setSettings] = useState([]);

    const [bookingWelcomePageHtml, setBookingWelcomePageHtml] = useState(null);
    const [villageBookingWelcomePageHtml, setVillageBookingWelcomePageHtml] = useState(null);

    const [calendarPeriods, setCalendarPeriods] = useState(null);
    
    const findSetting = (name)=> {
        return settings.find(s=>s.name==name);
    }

    const changeCalendarPeriod = (period, index) => {
        const clone = [...calendarPeriods];
        clone[index] = period;
        setCalendarPeriods(clone);
    }

    const addCalendarPeriod = () => {
        const clone = [...calendarPeriods];
        clone.push({startDate: dayjs().toDate(), endDate: dayjs().toDate(), name: ''})
        setCalendarPeriods(clone);
    }    

    const removeCalendarPeriod = (index) => {
        const clone = [...calendarPeriods];
        clone.splice(index, 1);
        setCalendarPeriods(clone);
    }    

    const fetchSettings = () => {
        fetch(`${config.server_base_url}/api/settings`)
            .then((response) => response.json())
            .then((data) => {
                setBookingWelcomePageHtml(data.settings.find(s=>s.name=='bookingWelcomePageHtml')?.value);
                setVillageBookingWelcomePageHtml(data.settings.find(s=>s.name=='villageBookingWelcomePageHtml')?.value);
                setSettings(data.settings);

                const calendarPeriodsSetting = data.settings.find(s=>s.name=='calendarPeriods')?.value;
                setCalendarPeriods(calendarPeriodsSetting ? JSON.parse(calendarPeriodsSetting).map(cp=>{return {name: cp.name, startDate: dayjs(cp.startDate).toDate(), endDate: dayjs(cp.endDate).toDate()}}) : []);
            })
            .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
    }      

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.settings-finance-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {
            fetch(`${config.server_base_url}/api/settings`, 
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({settings: [
                {name: 'accommodationVatPercentage',value: document.getElementsByName('accommodationVatPercentage')[0].value},
                {name: 'mealsVatPercentage',value: document.getElementsByName('mealsVatPercentage')[0].value},
                {name: 'bookingWelcomePageHtml',value: bookingWelcomePageHtml},
                {name: 'villageBookingWelcomePageHtml',value: villageBookingWelcomePageHtml},
                {name: 'calendarPeriods',value: JSON.stringify(calendarPeriods)},
              ]})
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {  toast.success( 'Settings saved!', {theme: 'colored'}) } else setErrorMsg(data.reason) })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    useEffect(() => {
        fetchSettings();
    },[])

    return (
      <div id='app-container' className="app-container">
        <Nav url="/settings"></Nav>
        <div className='page-header'><h1>Settings</h1></div>
        <div id='page-container' className="page-container settings-page">  
            {settings.length && (bookingWelcomePageHtml!=null) ? 
            <Fragment>
                <Tabs className="mb-3">
                    <Tab eventKey="calendar" title="Calendar">
                        <h2>Calendar Periods</h2>
                        <p style={{marginBottom: 30}}>Periods defined here will appear in the calendar view.</p>
                        {calendarPeriods && calendarPeriods.map((period, index)=>
                            <div key={index} className='calendar-period-row'>
                                <div>
                                    <label>Name:</label>
                                    <Form.Control type="text" onChange={(e)=>{period.name = e.target.value; changeCalendarPeriod(period, index)}} defaultValue={period.name} />
                                </div>
                                <div>
                                    <label>To:</label>
                                    <DatePicker selected={period.startDate} onChange={(date) => {period.startDate = date; changeCalendarPeriod(period, index)}} dateFormat="dd MMM, yyyy" calendarStartDay={1} onKeyDown={(e) => { e.preventDefault();}} />
                                </div>
                                <div>
                                    <label>From:</label>
                                    <DatePicker selected={period.endDate} onChange={(date) => {period.endDate = date; changeCalendarPeriod(period, index)}} dateFormat="dd MMM, yyyy" calendarStartDay={1} onKeyDown={(e) => { e.preventDefault();}} />
                                </div>
                                <div>
                                    <Button variant='light' onClick={()=>removeCalendarPeriod(index)}><IoRemoveOutline /></Button>
                                </div>
                            </div>
                        )}
                        <Button variant='light' onClick={()=>addCalendarPeriod()} style={{marginTop: 10}}><IoAddOutline /></Button>
                    </Tab>
                    <Tab eventKey="open-booking-links" title="Links">
                        <h2 style={{marginBottom: 10}}>Sahaja Bhavan Open Booking</h2>
                        <p>Pay Card: <a href="https://nivas.mooji.org/book?tk=85ARROpb" target="_blank">https://nivas.mooji.org/book?tk=85ARROpb</a></p>
                        <p>Pay Cash: <a href="https://nivas.mooji.org/book?tk=Bu1peC43&pc=1" target="_blank">https://nivas.mooji.org/book?tk=Bu1peC43&pc=1</a></p>

                        <p>Pay Card (€25): <a href="https://nivas.mooji.org/book?tk=7XGWcNau&p=sahaja_bhavan25" target="_blank">https://nivas.mooji.org/book?tk=7XGWcNau&p=sahaja_bhavan25</a></p>
                        <p>Pay Cash (€25): <a href="https://nivas.mooji.org/book?tk=K6jW9x8X&pc=1&p=sahaja_bhavan25" target="_blank">https://nivas.mooji.org/book?tk=K6jW9x8X&pc=1&p=sahaja_bhavan25</a></p>

                        <p>Pay Card (€20): <a href="https://nivas.mooji.org/book?tk=klXobm2V&p=sahaja_bhavan20" target="_blank">https://nivas.mooji.org/book?tk=klXobm2V&p=sahaja_bhavan20</a></p>
                        <p>Pay Cash (€20): <a href="https://nivas.mooji.org/book?tk=rPtH8e0w&pc=1&p=sahaja_bhavan20" target="_blank">https://nivas.mooji.org/book?tk=rPtH8e0w&pc=1&p=sahaja_bhavan20</a></p>

                        <p>Pay Card (€15): <a href="https://nivas.mooji.org/book?tk=gO4wYDim&p=sahaja_bhavan15" target="_blank">https://nivas.mooji.org/book?tk=gO4wYDim&p=sahaja_bhavan15</a></p>
                        <p>Pay Cash (€15): <a href="https://nivas.mooji.org/book?tk=VwssQnAv&pc=1&p=sahaja_bhavan15" target="_blank">https://nivas.mooji.org/book?tk=VwssQnAv&pc=1&p=sahaja_bhavan15</a></p>
                        
                        <p>Pay Zero: <a href="https://nivas.mooji.org/book?tk=cEQUoHGY&pc=1&p=sahaja_bhavan0" target="_blank">https://nivas.mooji.org/book?tk=cEQUoHGY&pc=1&p=sahaja_bhavan0</a></p>

                        <h2 style={{marginBottom: 10, marginTop: 40}}>Shanti Bhavan Open Booking</h2>
                        <p>Pay Card: <a href="https://nivas.mooji.org/book?tk=5TuLt2sr&p=shantib" target="_blank">https://nivas.mooji.org/book?tk=5TuLt2sr&p=shantib</a></p>
                        <p>Pay Cash: <a href="https://nivas.mooji.org/book?tk=rl1kIcaw&p=shantib&pc=1" target="_blank">https://nivas.mooji.org/book?tk=rl1kIcaw&p=shantib&pc=1</a></p>
                    </Tab>                    
                    <Tab eventKey="finance" title="Finance">
                        <Form noValidate onSubmit={save} className='settings-finance-form'>
                            {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                            <div>
                                <label>Accommodation VAT (%):</label>
                                <Form.Control type="number" required disabled name='accommodationVatPercentage' defaultValue={findSetting('accommodationVatPercentage')?.value} />
                            </div>
                            <div>
                                <label>Meals VAT (%):</label>
                                <Form.Control type="number" required disabled name='mealsVatPercentage' defaultValue={findSetting('mealsVatPercentage')?.value} />
                            </div>
                        </Form>
                    </Tab>
                    <Tab eventKey="sahaja-bookings" title="Sahaja Bookings">
                        <div>
                            <label>Sahaja Bookings Welcome Page:</label>
                            <ReactQuill modules={{clipboard: { matchVisual: false }}} theme="snow" value={bookingWelcomePageHtml} onChange={(v)=> {setBookingWelcomePageHtml(v)}} />
                        </div>
                    </Tab>
                    <Tab eventKey="village-bookings" title="Village Bookings">
                        <div>
                            <label>Village Bookings Welcome Page:</label>
                            <ReactQuill modules={{clipboard: { matchVisual: false }}} theme="snow" value={villageBookingWelcomePageHtml} onChange={(v)=> {setVillageBookingWelcomePageHtml(v)}} />
                        </div>
                    </Tab>
                </Tabs>
                <div className='form-row action-buttons'>
                    <Button variant="primary" disabled={!(isAdmin() || isSuperAdmin())} onClick={save}>Save</Button>
                </div>
            </Fragment> : <span></span> }
        </div>
        <ToastContainer />
      </div>
    )
  }