import { Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ConfirmationModal(props) {
    
    const [show, setShow] = useState(true);

    const close = () => {
        setShow(false);
        props.onCancel();
    }

    return (
        <Modal show={show} onHide={close}>
            <Modal.Header closeButton>
            <Modal.Title dangerouslySetInnerHTML={{__html: props.title}} />
            </Modal.Header>
            <Modal.Body>{props.body}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={close}>Close</Button>
            <Button variant="primary" onClick={() => {
                close();
                props.onConfirm();
            }}>
                Yes
            </Button>
            </Modal.Footer>
        </Modal>
    );
}