import React, { useEffect, useState } from "react";

import Nav from "../components/Nav.js";
import config from "../config.js";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {IoAddOutline} from "react-icons/io5";
import AddRoomTypeModal from "../components/AddRoomTypeModal.js";
import ConfirmationModal from "../components/ConfirmationModal.js";
import {MdMode, MdDelete} from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { genericErrorMsg, isSuperAdmin, isAdmin } from "../utils.js";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

export default function RoomTypesPage() {

    const [roomTypes, setRoomTypes] = useState([]);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [selectedRoomType, setSelectedRoomType] = useState(null);

    const fetchRoomTypes = () => {
        return fetch(`${config.server_base_url}/api/roomTypesFull`)
            .then((response) => response.json())
            .then((data) => setRoomTypes(data.roomTypes))
            .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
    }        

    const deleteRoomType = (roomTypeId) => {
        fetch(`${config.server_base_url}/api/roomTypes/${roomTypeId}`, 
            {
              method: 'DELETE',
              headers: { 'Content-Type': 'application/json' },
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) fetchRoomTypes() })
            .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
      }
    useEffect(() => {
        fetchRoomTypes();
    },[])

    return (
      <div id='app-container' className="app-container">
        <Nav url="/roomtypes"></Nav>
        <div className='page-header'><h1>Room Types</h1></div>
        <div id='page-container' className="page-container room-types-page">  
            <div className='data-table-container'>
                <div className='table-actions'>
                    <Button variant="primary" onClick={()=>{setShowAddModal(true); setSelectedRoomType(null); }} disabled={!(isAdmin() || isSuperAdmin())}><IoAddOutline /> Create</Button>
                </div>
                <Table striped bordered hover className='room-types-table'>
                    <tbody>
                        {roomTypes && roomTypes.length > 0 && roomTypes.map((roomType) => (
                            <tr key={roomType.id}>
                                <td className='room-type-name'>{roomType.name}</td>
                                <td className='room-type-photo'>
                                    {roomType.photos ?
                                        <ImageGallery disableKeyDown lazyLoad={true} showBullets={roomType.photos.length>1} showPlayButton={false} items={(roomType.photos.length ? roomType.photos : ['no_image.png']).map(p=>{
                                                return {
                                                    original: `${config.server_base_url}/static/uploads/${p}`,
                                                    thumbnail: roomType.photos.length>1 ? `${config.server_base_url}/static/uploads/${p}` : null
                                                }
                                            })} /> : <span></span>}
                                </td>
                                <td className='room-type-description'>
                                    <div dangerouslySetInnerHTML={{ __html: roomType.description }} />
                                </td>
                                <td className='room-type-stats'>
                                {roomType.locations.length > 0 && roomType.locations.map((location) => (
                                        (location.rooms.length>0) ?
                                        <div key={roomType.id + "_" + location.id}>
                                            <span className="room-type-location">{location.name}</span>: {location.rooms.length} beds
                                        </div> : <span key={roomType.id + "_" + location.id + "_empty"}></span>
                                    ))}
                                </td>
                                <td className='row-actions'>
                                    <Button variant='light' onClick={()=> {setShowAddModal(true); setSelectedRoomType({roomTypeId: roomType.id, roomTypeName: roomType.name, roomTypeDescription: roomType.description, roomTypePhotos: roomType.photos, roomTypeMaxOccupants: roomType.maxOccupants}); }}><MdMode /></Button>
                                    <Button variant='light' onClick={()=>{setShowDeleteModal(true); setSelectedRoomType({roomTypeId: roomType.id, roomTypeName: roomType.name});}} disabled={!(isAdmin() || isSuperAdmin())}><MdDelete /></Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
        {showAddModal ? <AddRoomTypeModal {...selectedRoomType} onCancel={()=>setShowAddModal(false)} onSave={()=>fetchRoomTypes()} /> : <span></span>}
        {showDeleteModal ? <ConfirmationModal title={`Are you sure you want to delete <em>${selectedRoomType.roomTypeName}</em> ?`} body="This is irreversible." onCancel={()=>setShowDeleteModal(false)} onConfirm={()=>deleteRoomType(selectedRoomType.roomTypeId)} /> : <span></span>}
        <ToastContainer />
      </div>
    )
  }