import React, {Fragment, useEffect, useState} from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import LoadingSpinner from "../components/LoadingSpinner.js";
import config from "../config";
import { genericErrorMsg } from "../utils.js";
import dayjs from 'dayjs';
import {IoAddOutline} from "react-icons/io5";
import {MdMode, MdDelete} from "react-icons/md";
import ConfirmationModal from "../components/ConfirmationModal.js";
import AddOfflandDatesModal from "../components/AddOfflandDatesModal.js";

export default function MyBookingOfflandDatesPage() {

  let { bookingId } = useParams();

  const [booking, setBooking] = useState(null);
  const [offlandDates, setOfflandDates] = useState(null);

  const [selectedDates, setSelectedDates] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fetchOfflandDates = () => {
    fetch(`${config.server_base_url}/api/my-booking/${bookingId}/offlandDates`)
        .then((response) => response.json())
        .then((data) => setOfflandDates(data.offlandDates))
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }

  const deleteOfflandDates = (offlandDatesId) => {
    fetch(`${config.server_base_url}/api/my-booking/${bookingId}/offlandDates/${offlandDatesId}`, 
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) fetchOfflandDates() })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }  

  const fetchBooking = () => {
    fetch(`${config.server_base_url}/api/my-booking/${bookingId}`)
        .then((response) => response.json())
        .then((data) => setBooking(data.booking))
}  

  useEffect(() => {
    fetchOfflandDates();
    fetchBooking();
  },[])

  return (
    <div className="app-container my-booking-app">
      <div className="page-container my-booking-page my-booking-offland-dates-page">  
          <h1>Offland Dates</h1>
          <h3><center>{booking ? `${booking.booking.guest.preferredName} ${booking.booking.guest.lastName}`  : ''}</center></h3>
          <center><p className='form-info'>Please add an entry with your dates when you know for sure that you are leaving. If the details change slightly later, that is okay, you can return to update it.</p></center>
          <div className='table-actions'>
            {booking ? <Button variant="primary" onClick={()=>{setSelectedDates(null); setShowAddModal(true); }}><IoAddOutline /> Add Offland Dates</Button> : <Fragment />}
          </div>
          {offlandDates && booking ? 
            (offlandDates.length ?
              <Table striped bordered hover className="offland-dates-table">
                <thead>
                  <tr>
                    <td>Departure Date</td>
                    <td>Arrival Date</td>
                    <td>Number of Days</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                {offlandDates.map((od, index)=>
                {
                  const lastPayment = booking.booking.payments.length>0 ? booking.booking.payments[0] : null;
                  const canDelete = booking.nextPayment==null || booking.nextPayment.startDate==null || dayjs(booking.nextPayment.startDate).isBefore(dayjs(od.startDate)) || lastPayment==null || dayjs(lastPayment.date).isBefore(dayjs(od.startDate));
                  const canEdit = booking.nextPayment==null || booking.nextPayment.startDate==null || dayjs(booking.nextPayment.startDate).isBefore(dayjs(od.endDate)) || lastPayment==null || dayjs(lastPayment.date).isBefore(dayjs(od.startDate));

                  return <tr key={index}>
                    <td>{dayjs(od.startDate).format('DD MMM YYYY')}</td>
                    <td>{dayjs(od.endDate).format('DD MMM YYYY')}</td>
                    <td>{dayjs(od.endDate).startOf('day').diff(dayjs(od.startDate).startOf('day'), 'day')}</td>
                    <td className='row-actions'>{canEdit ? <Button variant='light' onClick={()=> {setShowAddModal(true); setSelectedDates(od); }}><MdMode /></Button> : <Fragment />} {canDelete ? <Button variant='light' onClick={()=>{setShowDeleteModal(true); setSelectedDates(od);}}><MdDelete /></Button> : <Fragment />}</td>
                  </tr>
                })}
                </tbody>
              </Table> : <center>Manage your offland dates here.</center>) : <LoadingSpinner /> 
          }
      </div>
      {showAddModal ? <AddOfflandDatesModal booking={booking} bookingId={bookingId} {...selectedDates} onCancel={()=>setShowAddModal(false)} onSave={()=>fetchOfflandDates()} /> : <span></span>}
      {showDeleteModal ? <ConfirmationModal title={`Are you sure you want to delete these offland dates ?`} body="This is irreversible." onCancel={()=>setShowDeleteModal(false)} onConfirm={()=>deleteOfflandDates(selectedDates.id)} /> : <span></span>}
      <ToastContainer />
    </div>
  )
}