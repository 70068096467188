import React, { useState, Fragment } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RoomsPage from './pages/RoomsPage.js';
import BookingsPage from './pages/BookingsPage.js';
import RatesPage from './pages/RatesPage.js';
import AddBookingPage from './pages/AddBookingPage.js';
import BookingDetailsPage from './pages/BookingDetailsPage.js';
import MyBookingPage from './pages/MyBookingPage.js';
import PaymentsDuePage from './pages/PaymentsDuePage.js';
import LocationsPage from './pages/LocationsPage.js';
import RoomTypesPage from './pages/RoomTypesPage.js';
import SettingsPage from './pages/SettingsPage.js';
import LoginPage from './pages/LoginPage.js';
import MyBookingOfflandDatesPage from './pages/MyBookingOfflandDatesPage.js';
import ReportsPage from './pages/ReportsPage.js';
import OpenBookingPage from './pages/OpenBookingPage.js';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<BookingsPage />} />
          <Route path="calendar" element={<BookingsPage />} />
          <Route path="bookings">
            <Route path="edit" element={<AddBookingPage />} />
            <Route path=":bookingId" element={<BookingDetailsPage />} />
          </Route>
          <Route path="payments" element={<PaymentsDuePage />} />
          <Route path="rooms" element={<RoomsPage />} />
          <Route path="locations" element={<LocationsPage />} />
          <Route path="roomtypes" element={<RoomTypesPage />} />
          <Route path="rates" element={<RatesPage />} />
          <Route path="reports" element={<ReportsPage />} />
          <Route path="settings" element={<SettingsPage />} />          
          <Route path="my-booking/:bookingId" element={<MyBookingPage />} />
          <Route path="my-booking/:bookingId/offland" element={<MyBookingOfflandDatesPage />} />
          <Route path="book" element={<OpenBookingPage />} />
          <Route path="login" element={<LoginPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
